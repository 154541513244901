
import {defineComponent} from 'vue';
import CxLabel from "@/components/CxLabel.vue";
import {EoxRecordDto} from "@/types/dto";

export default defineComponent({
  props: {
    eoxRecordData: EoxRecordDto,
  },
});
