
import {defineComponent, PropType} from "vue";
import useVuelidate from "@vuelidate/core";
import {IPPoolSubnetDto, VlanDto} from "@/types/dto";
import CxLabel from "@/components/CxLabel.vue";
import CxDeleteButton from "@/components/CxDeleteButton.vue";
import CxCreateButton from "@/components/CxCreateButton.vue";
import {between, helpers, required} from "@vuelidate/validators";
import CxDropdown from "@/components/CxDropdown.vue";

export default defineComponent({
  components: {CxDropdown, CxCreateButton, CxDeleteButton, CxLabel},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    subnet: {
      type: Object as PropType<IPPoolSubnetDto>,
      required: true,
    },
    vlanOptions: {
      type: Array as PropType<VlanDto[]>,
      required: true,
    },
  },
  data() {
    return {
      errors: [] as string[],
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    if (!this.visible) {
      return {};
    }
    const ipAddress = helpers.withMessage(this.$t('ipPool', "subnetInvalidIp"), (value) => {
      const ipPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^$/
      return ipPattern.test(<string>value)
    })

    return {
      subnet: {
        startIp: {required, ipAddress},
        endIp: {required, ipAddress},
        subnetIp: {required, ipAddress},
        gatewayIp: {required, ipAddress},
        subnetPrefix: {required, between: between(1, 32)},
        broadcastIp: {required, ipAddress},
      },
    };
  },
  methods: {
    addIP() {
      if (this.subnet) {
        this.subnet.usedAddresses!.push('');
        this.errors.push('');
      }
    },
    removeIP(index: number) {
      if (this.subnet) {
        this.subnet.usedAddresses!.splice(index, 1);
        this.errors.splice(index, 1);
      }
    },
    validateIP(index: number) {
      const ipPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      if (this.subnet && !ipPattern.test(this.subnet.usedAddresses![index])) {
        this.errors[index] = this.$t('ipPool', "subnetInvalidIp");
      } else {
        this.errors[index] = '';
      }
    },
    save() {
      if(this.visible) {
        if (this.v$.$invalid) {
          this.$cx.notifyValidationError(this.v$);
          return;
        }
        let hasError = false;
        this.subnet.usedAddresses!.forEach((ip, index) => {
          this.validateIP(index);
          if (this.errors[index]) {
            hasError = true;
          }
        });
        if (hasError) {
          this.$cx.error(this.$t('ipPool', "subnetIPError"), this.$t('ipPool', "subnetIPErrorDesc"));
          return;
        }
        this.$store
            .dispatch("ipPool/updateSubnet", {id: 0, entity: this.subnet})
            .then(() => {
              this.$cx.success(this.$t('ipPool', "subnetUpdated"), this.$t('ipPool', "subnetUpdatedDesc"))
              this.close();
            })
            .catch((error) => this.$cx.error(error, this.$t('ipPool', "subnetUpdatedError")));

      }
    },
    close() {
      this.$emit("update:visible", false);
    },
  },
});
