import { api } from "@/_config/api";

const url = "bugApi"

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  actions: {
    async saveBugsForAllDevices(context: any) {
      context.state.loading = true;
      try {
        let response = await api.post(url + `/init`,null,{
          timeout: 18000000
        });
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async saveBugsForDeviceModel(context: any, modelId: number) {
      context.state.loading = true;
      try {
        let response = await api.post(url + `/model/${modelId}`,null,{
          timeout: 1200000
        });
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getBugsForDeviceModel(context: any, props: any) {
      context.state.loading = true;
      try {
        let response = await api.get((url + `/model/${props.modelId}`),{
              params: {
                page: props.page,
                size: props.size,
              }
            }
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
  }
}
