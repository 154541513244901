import CxBaseStoreModule from "@/types/cx-store-module";
import { OSDto } from "@/types/dto";
import { api } from "@/_config/api";

class OSStore extends CxBaseStoreModule<OSDto> {

    constructor() { super("os") }

    defineState(state: any) {
        return super.defineState({
            osLoading: false,
            osProgress: 0
        })
    }

    defineActions(actions: any) {
        return super.defineActions({

            // OS-Image create and Fileupload in one request
            create: async (context: any, data: any) => {
                context.state.osLoading = true;
                context.state.osProgress = 0;
                try {
                    let files = (data.files as File[])
                    let file = files[0]
                    let form = new FormData()
                    form.append("file", file)
                    form.append("name", data.entity.name)
                    form.append("version", data.entity.version)

                    let response = await api.post(context.state.entityName, form, {
                        onUploadProgress: (progressEvent) => {
                            context.state.osProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total!)
                        },
                    });
                    context.state.osLoading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.osLoading = false;
                    return Promise.reject(error);
                }
            },

            // OS-Image File Upload
            uploadFiles: async (context: any, data: any) => {
                context.state.osLoading = true;
                try {
                    let files = (data.files as File[])
                    let file = files[0]
                    let form = new FormData()
                    //files.forEach(f => {
                    //    form.append("file", f)
                    //})
                    form.append("file", file)
                    let response = await api.post(`${context.state.entityName}/${data.id}/upload`, form, {
                        timeout: 0,
                        onUploadProgress: (progressEvent) => {
                            context.state.osProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total!)
                        },
                    });
                    context.state.osLoading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.osLoading = false;
                    return Promise.reject(error);
                }
            },
            // OS-Image File Upload
            uploadImageFiles: async (context: any, data: any) => {
                context.state.osLoading = true;
                try {
                    let files = (data.files as File[])
                    let file = files[0]
                    let form = new FormData()
                    //files.forEach(f => {
                    //    form.append("file", f)
                    //})
                    form.append("file", file)
                    let response = await api.post(`${context.state.entityName}/${data.id}/images/upload`, form, {
                        timeout: 0,
                        onUploadProgress: (progressEvent) => {
                            context.state.osProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total!)
                        },
                    });
                    context.state.osLoading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.osLoading = false;
                    return Promise.reject(error);
                }
            },

            // OS-Image File Download
            download: async (context: any, id: any) => {
                let response = await api.get(`${context.state.entityName}/download/${id}`, {
                    responseType: 'blob'
                })

                let attachment =
                    response.headers["content-disposition"] ||
                    response.headers["Content-Disposition"];
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                let filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(
                    attachment!
                )![0];
                filename = filename.split("=")[1].replaceAll('"', "");
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();

            },

            // OS-Image File Download
            downloadImage: async (context: any, params: any) => {
                let response = await api.get(`${context.state.entityName}/download/${params.id}/${params.filename}`, {
                    responseType: 'blob'
                })

                let attachment =
                    response.headers["content-disposition"] ||
                    response.headers["Content-Disposition"];
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                let filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(
                    attachment!
                )![0];
                filename = filename.split("=")[1].replaceAll('"', "");
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();

            }
        })
    }
}

export default new OSStore().createStore()
