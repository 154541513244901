import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-form-h-30" }
const _hoisted_2 = { class: "cx-dt-dd-option" }
const _hoisted_3 = {
  key: 0,
  class: "flex"
}
const _hoisted_4 = { class: "cx-chips" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = {
  key: 0,
  class: "ml-3 pi pi-check",
  style: {"color":"green"}
}
const _hoisted_7 = {
  key: 1,
  class: "ml-3 pi pi-times",
  style: {"color":"red"}
}
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = {
  key: 0,
  class: "ml-3 pi pi-check",
  style: {"color":"green"}
}
const _hoisted_10 = {
  key: 1,
  class: "ml-3 pi pi-times",
  style: {"color":"red"}
}
const _hoisted_11 = { class: "flex" }
const _hoisted_12 = { class: "flex-1" }
const _hoisted_13 = { class: "flex-1" }
const _hoisted_14 = { class: "relative" }
const _hoisted_15 = { class: "rl-selector-add" }
const _hoisted_16 = { class: "rl-selectors-list" }
const _hoisted_17 = { class: "flex" }
const _hoisted_18 = { class: "rl-selectors-list" }
const _hoisted_19 = { class: "flex" }
const _hoisted_20 = { class: "cx-form-v" }
const _hoisted_21 = { class: "flex justify-content-end gap-2 mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_save_button = _resolveComponent("cx-save-button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_cx_compliance_view = _resolveComponent("cx-compliance-view")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Button = _resolveComponent("Button")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_cx_config_comparer = _resolveComponent("cx-config-comparer")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: _ctx.$store.state.rule.loading,
    title: _ctx.$cx.editorTitle(_ctx.isEditing, 'rule'),
    detail: _ctx.entity.name
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_cx_save_button, {
        isEditing: _ctx.isEditing,
        onSave: _ctx.save,
        onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo('rule'))),
        grant: {
        perm: _ctx.$perm.DEVICE_MODEL,
        lvl: _ctx.$lvl.WRITE
      }
      }, null, 8, ["isEditing", "onSave", "grant"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_cx_label, {
            label: _ctx.$c('name'),
            v$: _ctx.v$.entity.name
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InputText, {
                placeholder: _ctx.$c('name_ph'),
                modelValue: _ctx.entity.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.name) = $event)),
                disabled: !_ctx.$auth.permits(_ctx.$perm.DEVICE_MODEL, _ctx.$lvl.WRITE)
              }, null, 8, ["placeholder", "modelValue", "disabled"])
            ]),
            _: 1
          }, 8, ["label", "v$"]),
          _createVNode(_component_cx_label, {
            label: _ctx.$t('deviceFamilyGroup', 'lb'),
            prop: "deviceFamilyGroup"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cx_dropdown, {
                modelValue: _ctx.entity.deviceFamilyGroup,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.deviceFamilyGroup) = $event)),
                store: "deviceFamilyGroup",
                optionLabel: "name",
                placeholder: _ctx.$t('deviceFamilyGroup', 'ph'),
                dropdownOptions: {
              route: 'deviceFamilyGroupEditor',
              getId: (en) => en?.id,
              editable: true
            }
              }, {
                option: _withCtx(({ option }) => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("p", null, [
                      _createElementVNode("strong", null, _toDisplayString(option?.name), 1)
                    ]),
                    (option?.deviceFamilies.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(option?.deviceFamilies, (role, r) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "cx-chip",
                                key: r
                              }, _toDisplayString(role.name), 1))
                            }), 128))
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "placeholder", "dropdownOptions"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        (_ctx.isEditing)
          ? (_openBlock(), _createBlock(_component_cx_config_comparer, {
              key: 0,
              isDiff: false,
              hasThirdColumn: true,
              onOnDeviceChanged: _ctx.onDeviceChanged,
              ref: "diff"
            }, {
              diff: _withCtx(() => [
                _createVNode(_component_Accordion, { multiple: true }, {
                  default: _withCtx(() => [
                    (Object.keys(_ctx.complianceResult).length < 1)
                      ? (_openBlock(), _createBlock(_component_AccordionTab, { key: 0 }, {
                          header: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('rule', 'noResultsTitle')), 1)
                          ]),
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('rule', 'noResultsBody')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.complianceResult, (obj, s) => {
                      return (_openBlock(), _createBlock(_component_AccordionTab, {
                        key: _ctx.$cx.createUid()
                      }, {
                        header: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("span", null, _toDisplayString(obj.selector.name) + " | " + _toDisplayString(obj.selector.text), 1),
                            (_ctx.isCompliant(obj.selector))
                              ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                              : (_openBlock(), _createElementBlock("i", _hoisted_7))
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_Accordion, { multiple: true }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(obj.items, (match, s) => {
                                return (_openBlock(), _createBlock(_component_AccordionTab, {
                                  key: _ctx.$cx.createUid()
                                }, {
                                  header: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_8, [
                                      _createElementVNode("span", null, _toDisplayString(match.source?.text || '* Missing *'), 1),
                                      (match.compliant)
                                        ? (_openBlock(), _createElementBlock("i", _hoisted_9))
                                        : (_openBlock(), _createElementBlock("i", _hoisted_10))
                                    ])
                                  ]),
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_11, [
                                      _createElementVNode("div", _hoisted_12, [
                                        _createVNode(_component_cx_compliance_view, {
                                          source: match.source,
                                          errors: match.errors,
                                          isSource: true,
                                          skipChildren: true
                                        }, null, 8, ["source", "errors"]),
                                        _createVNode(_component_cx_compliance_view, {
                                          source: match.source,
                                          errors: match.errors,
                                          isSource: true
                                        }, null, 8, ["source", "errors"])
                                      ]),
                                      _createElementVNode("div", _hoisted_13, [
                                        _createVNode(_component_cx_compliance_view, {
                                          source: match.target,
                                          errors: match.errors,
                                          isSource: false,
                                          skipChildren: true
                                        }, null, 8, ["source", "errors"]),
                                        _createVNode(_component_cx_compliance_view, {
                                          source: match.target,
                                          errors: match.errors,
                                          isSource: false
                                        }, null, 8, ["source", "errors"])
                                      ])
                                    ])
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              third: _withCtx(() => [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_Button, {
                      class: "p-button-text",
                      icon: "pi pi-plus",
                      disabled: !_ctx.isEditing,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onOpenSelectorEditor({}, true)))
                    }, null, 8, ["disabled"])
                  ]),
                  _createVNode(_component_TabView, {
                    onTabChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.activeSelectorTabIndex = $event.index))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TabPanel, { header: "Selektoren" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_16, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectors, (selector, s) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "rl-box",
                                key: selector.id
                              }, [
                                _createElementVNode("div", _hoisted_17, [
                                  _createVNode(_component_InputText, {
                                    placeholder: _ctx.$c('name_ph'),
                                    modelValue: selector.name,
                                    "onUpdate:modelValue": ($event: any) => ((selector.name) = $event),
                                    readonly: true
                                  }, null, 8, ["placeholder", "modelValue", "onUpdate:modelValue"]),
                                  _createVNode(_component_InputText, {
                                    class: "ml-1",
                                    placeholder: "Regex",
                                    modelValue: selector.text,
                                    "onUpdate:modelValue": ($event: any) => ((selector.text) = $event),
                                    readonly: true
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                  _createVNode(_component_Button, {
                                    class: "p-button-text",
                                    icon: "pi pi-pencil",
                                    onClick: ($event: any) => (_ctx.onOpenSelectorEditor(selector, false))
                                  }, null, 8, ["onClick"]),
                                  _createVNode(_component_Button, {
                                    class: "p-button-text p-button-danger",
                                    icon: "pi pi-times",
                                    onClick: ($event: any) => (_ctx.onDeleteSelector(selector))
                                  }, null, 8, ["onClick"])
                                ])
                              ]))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_TabPanel, { header: "Blacklist" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_18, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blacklist, (blacklistItem, s) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "rl-box",
                                key: blacklistItem.id
                              }, [
                                _createElementVNode("div", _hoisted_19, [
                                  _createVNode(_component_InputText, {
                                    placeholder: _ctx.$c('name_ph'),
                                    modelValue: blacklistItem.name,
                                    "onUpdate:modelValue": ($event: any) => ((blacklistItem.name) = $event),
                                    readonly: true
                                  }, null, 8, ["placeholder", "modelValue", "onUpdate:modelValue"]),
                                  _createVNode(_component_InputText, {
                                    class: "ml-1",
                                    placeholder: "Regex",
                                    modelValue: blacklistItem.text,
                                    "onUpdate:modelValue": ($event: any) => ((blacklistItem.text) = $event),
                                    readonly: true
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                  _createVNode(_component_Button, {
                                    class: "p-button-text",
                                    icon: "pi pi-pencil",
                                    onClick: ($event: any) => (_ctx.onOpenSelectorEditor(blacklistItem, false))
                                  }, null, 8, ["onClick"]),
                                  _createVNode(_component_Button, {
                                    class: "p-button-text p-button-danger",
                                    icon: "pi pi-times",
                                    onClick: ($event: any) => (_ctx.onDeleteSelector(blacklistItem))
                                  }, null, 8, ["onClick"])
                                ])
                              ]))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["onOnDeviceChanged"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Dialog, {
          visible: _ctx.selectorEditorVisible,
          "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectorEditorVisible) = $event)),
          modal: "",
          header: _ctx.selectorEditorCreateMode ? 'Selektor erstellen' : 'Selektor bearbeiten',
          style: { width: '50rem' }
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_InputText, {
                placeholder: _ctx.$c('name_ph'),
                modelValue: _ctx.selectorEntity.name,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectorEntity.name) = $event))
              }, null, 8, ["placeholder", "modelValue"]),
              _createVNode(_component_InputText, {
                placeholder: "Regex",
                modelValue: _ctx.selectorEntity.text,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectorEntity.text) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createVNode(_component_Button, {
                type: "button",
                label: "Abbrechen",
                severity: "secondary",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.selectorEditorVisible = false))
              }),
              (_ctx.selectorEditorCreateMode)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    type: "button",
                    label: "Erstellen",
                    severity: "success",
                    onClick: _ctx.onSaveSelector
                  }, null, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    type: "button",
                    label: "Speichern",
                    severity: "success",
                    onClick: _ctx.onSaveSelector
                  }, null, 8, ["onClick"]))
            ])
          ]),
          _: 1
        }, 8, ["visible", "header"])
      ])
    ]),
    _: 1
  }, 8, ["loading", "title", "detail"]))
}