
import { CxEntityType, CxJobType, CxSchedulePattern, ScheduleDto, ScheduleStatusDto, JobDto, ScheduleJobDto, CxJobStatus, CxJobResult } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { CxFormApi } from "@/types/cx-form-api";
import { defineComponent, PropType } from "vue";
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import { ApprovalState } from "@/types/approval-state";

export default defineComponent({
    props: {
        editorApi: {
            type: Object as PropType<CxFormApi>,
        },
        approvalState: {
            type: Object as PropType<ApprovalState>,
            default: new ApprovalState(CxEntityType.GLOBAL)
        }
    },
    data() {
        return {
            id: -1 as number,
            entity: {
                maxJobFailureCount: -1,
                jobType: CxJobType.ROLLOUT,
                executionCount: 0,
                interval: false,
              firstInterval: null as unknown as number,
              secondInterval: null as unknown as number,
              thirdInterval: null as unknown as number
            } as ScheduleDto,
            initialEntity: {
                maxJobFailureCount: -1,
                jobType: CxJobType.ROLLOUT,
                executionCount: 0,
                interval: false
            } as ScheduleDto,
          createInGroups: false,
            autoScroll: true,
            isDeviceRoleTree: false,
            autoScrollInterval: null as any,
            patternOptions: this.$cx.mapEnumToOptions(CxSchedulePattern),
            jobTypeOptions: this.$cx.mapEnumToOptions(CxJobType),
            locationDeviceOptions: [] as any[],
            selectedJobKey: {} as any,
            selectedLog: null as any,
            expandedKeys: {} as any,
            treeNodes: [] as any[],
            deviceCount: 0,
            selectedJob: {
                id: -1,
                output: {}
            } as JobDto,
            statsInterval: null as any,
            initialExpand: false,
            apiData: {} as ScheduleStatusDto,
            doughnutOptions: {
                responsive: false,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057',
                            padding: 2,
                            align: 'start'
                        },
                        position: 'right'
                    }
                }
            },
            jobResultChart: {
                labels: [CxJobResult.SUCCESS, CxJobResult.ERROR, CxJobResult.NONE] as string[],
                datasets: [
                    {
                        data: [0, 0, 0],
                        backgroundColor: ["#24387f", "#f12938", "#bdbdbd"]
                    }
                ]
            },
            jobStatusChart: {
                labels: [CxJobStatus.CREATED, CxJobStatus.PENDING, CxJobStatus.PROCESSING, CxJobStatus.CANCELLED, CxJobStatus.DONE] as string[],
                datasets: [
                    {
                        data: [0, 0, 0, 0, 0],
                        backgroundColor: ["#009ddc", "#fdd87d", "#f57c00", "#a69fc2", "#24387f"]
                    }
                ]
            }
        };
    },
    setup: () => ({ v$: useVuelidate() as any }),
    validations() {
        return {
          entity: {
            name: {required, maxLength: maxLength(vMaxLen.name), $autoDirty: true},
            description: {maxLength: maxLength(vMaxLen.description), $autoDirty: true},
            jobType: {required, $autoDirty: true},
            locationDevices: {required, $autoDirty: true},
            codeTemplate: {required, $autoDirty: true},
            maxJobFailureCount: {required, $autoDirty: true},
            firstInterval: { required: this.createInGroups },
            secondInterval: { required: this.createInGroups },
            thirdInterval: { required: this.createInGroups }
          },
        };
    },
    computed: {
        isEditing() {
            return this.id > 0;
        },
        patternDescription() {
            let time
            try {
                if (this.entity.patternTime == null) return ""
                time = new Date(this.entity.patternTime)
            } catch {
                return ""
            }
            switch (this.entity.pattern) {
                case CxSchedulePattern.MINUTELY:
                    return this.$cx.tF('schedule', 'minutely', [time.getMinutes()])
                case CxSchedulePattern.HOURLY:
                    return this.$cx.tF('schedule', 'hourly', [time.getHours()])
                case CxSchedulePattern.DAILY:
                    return this.$cx.tF('schedule', 'daily', [this.$cx.leadingZero(time.getHours()), this.$cx.leadingZero(time.getMinutes())])
                case CxSchedulePattern.WEEKLY:
                    return this.$cx.tF('schedule', 'weekly', [this.$cx.getWeekday(time), this.$cx.leadingZero(time.getHours()), this.$cx.leadingZero(time.getMinutes())])
                case CxSchedulePattern.MONTHLY:
                    return this.$cx.tF('schedule', 'monthly', [time.getDate()])
                case CxSchedulePattern.YEARLY:
                    return this.$cx.tF('schedule', 'yearly', [time.getDate(), this.$cx.getMonth(time)])
            }
            return ""
        }
    },
    beforeUnmount() {
        clearInterval(this.statsInterval)
        clearInterval(this.autoScrollInterval)
    },
    created() {
        this.onAutoScrollChanged()
        this.editorApi!.onSave = async (closeFn: any) => {
            if (await this.$cx.notifyValidationError(this.v$)) return false;
            if (this.entity.pattern == CxSchedulePattern.MINUTELY) {
                if (this.entity.patternTime != null)
                    if (new Date(this.entity.patternTime!.toString()).getMinutes() < 3) {
                        this.$cx.warn("Ungültige Zeit", "Zeitpunkt darf nicht kürzer als 3 Minuten sein", 20000)
                        return
                    }
            }

            if (this.isEditing) {
                this.$store
                    .dispatch("schedule/save", this.entity)
                    .then(() => {
                        this.$cx.notifySaved(this.$t("schedule", "lb"));
                        this.initialEntity = this.$cx.getState(this.entity);
                        closeFn();
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
            } else if(this.createInGroups) {
              this.$store
              .dispatch("schedule/createInGroups", this.entity)
              .then((data) => {
                this.$cx.notifyCreated(this.$t("schedule", "lb"));
                this.editorApi!.load(data.id);
                this.initialEntity = this.$cx.getState(this.entity);
                closeFn(data)
              })
              .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
            } else {
                this.$store
                    .dispatch("schedule/create", this.entity)
                    .then((data) => {
                        this.$cx.notifyCreated(this.$t("schedule", "lb"));
                        this.editorApi!.load(data.id);
                        this.initialEntity = this.$cx.getState(this.entity);
                        closeFn(data)
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
            }
        };
        this.editorApi!.load = (id: number) => {
            this.id = id;
            if (!this.isEditing) return
            this.$store.dispatch("schedule/getById", id).then((entity) => {
                this.entity = entity;
                this.initialEntity = this.$cx.getState(this.entity);
                this.$emit("entity", entity, this.initialEntity)
                this.scrollOutput()
              if (typeof this.entity.firstInterval === 'number') {
                this.createInGroups = true;
              }
            });
            this.initStats()
        };
        this.editorApi!.delete = (id: number) => {
            this.$store.dispatch("schedule/delete", id).then(() => {
                this.$cx.notifyDeleted(this.$t("schedule", "lb"));
            });
        };
        this.editorApi!.notifyUnsavedChanges = (next: any) => {
            this.$cx.notifyUnsavedChanges(next, this.initialEntity, this.entity);
        }
        this.editorApi!.reset = () => {
            this.entity = {
                activated: true
            } as ScheduleDto;
        };
        this.editorApi!.isEditing = () => this.id > 0;
    },
    methods: {
        applyEntity(e: any) {
            this.entity = { ...this.entity, ...e }
            this.entity.jobType = CxJobType.CUSTOM
            this.initialEntity = this.$cx.getState(this.entity)
        },
        initStats() {
            clearInterval(this.statsInterval)
            this.statsInterval = setInterval(this.loadStats, 3000)
            this.loadStats()
        },
        loadStats() {
            if (!this.isEditing) return
            this.$store.dispatch("schedule/getScheduleStatus", {
                scheduleId: this.entity.id,
                jobId: this.selectedJob.id
            }).then((data: ScheduleStatusDto) => {
                this.apiData = data
                this.entity.state = data.state
                this.entity.activated = data.activated
                this.entity.nextExecutionTime = data.nextExecutionTime
                this.entity.lastExecutionTime = data.lastExecutionTime
                if (!this.$cx.isNullOrEmpty(data.scheduleLog))
                    this.entity.scheduleLog = data.scheduleLog

                if (this.selectedJob != null && data.job != null) {
                    if (this.selectedJob.id == data.job.id) {
                        this.selectedJob = data.job
                    }
                }
                if (data.job != null) {
                    this.selectedJob = data.job
                } else {
                    this.selectedJob = {
                        id: 0,
                        output: {}
                    } as JobDto
                }

                this.refreshTree()
            })
        },
        onLocationDeviceChanged(ev: any) {
            if (ev.isDevice) {
                this.selectedJob.id = ev.jobId
                this.initStats()
            }
        },
        onActivationChanged() {
            clearInterval(this.statsInterval)
            this.selectedJob = {
                id: -1,
                output: {}
            }
            this.initStats()
        },
        expandAll() {
            for (let node of this.treeNodes)
                this.expandNode(node);
            this.expandedKeys = { ...this.expandedKeys };
        },
        expandNode(node: any) {
            if (node.children && node.children.length) {
                this.expandedKeys[node.key] = true;
                for (let child of node.children)
                    this.expandNode(child);
            }
        },
        getLines(text: any) {
            if (text == null) return ""
            if (this.$cx.isNullOrEmpty(text.trim()))
                return ""
            return ` (${text.split('\n').length})`
        },
        scrollOutput() {
            this.$nextTick(() => {
                [1, 2, 3, 4, 5].forEach(k => {
                    let el1 = document.getElementById('job-out-' + k) as any
                    if (el1 != null)
                        el1.scrollTop = el1.scrollHeight;
                })
            })
        },
        getJobStatusStyle(status: CxJobStatus) {
            switch (status) {
                case CxJobStatus.CREATED:
                    return 'background-color: #009ddc'
                case CxJobStatus.PENDING:
                    return 'background-color: #fdd87d; animation: blinker 1s linear infinite'
                case CxJobStatus.PROCESSING:
                    return 'background-color: #f57c00; animation: blinker 1s linear infinite'
                case CxJobStatus.CANCELLED:
                    return 'background-color: #a69fc2'
                case CxJobStatus.DONE:
                    return 'background-color: #24387f'
            }
        },
        getLocResultStyle(result: string) {
            switch (result) {
                case "WARN":
                    return "background-color: #f9ae61"
                case "ERROR":
                    return "background-color: #f12938"
                case "SUCCESS":
                    return "background-color: #24387f"
            }
        },
        getJobResultStyle(result: CxJobResult) {
            switch (result) {
                case CxJobResult.SUCCESS:
                    return 'background-color: #24387f'
                case CxJobResult.ERROR:
                    return 'background-color: #f12938'
            }
        },
        computeCharts() {
            let valueMap = {} as { [key: string]: number }
            Object.keys(CxJobStatus).forEach(k => valueMap[k] = 0)
            Object.keys(CxJobResult).forEach(k => valueMap[k] = 0)

            this.treeNodes.forEach((l: any) => {
                l.children.forEach((c: any) => {
                    valueMap[c.status] += 1
                    valueMap[c.result] += 1
                })
            })

            this.jobResultChart.datasets[0].data[0] = valueMap[CxJobResult.SUCCESS]
            this.jobResultChart.datasets[0].data[1] = valueMap[CxJobResult.ERROR]
            this.jobResultChart.datasets[0].data[2] = valueMap[CxJobResult.NONE]

            this.jobResultChart.labels[0] = valueMap[CxJobResult.SUCCESS] + " " + CxJobResult.SUCCESS
            this.jobResultChart.labels[1] = valueMap[CxJobResult.ERROR] + " " + CxJobResult.ERROR
            this.jobResultChart.labels[2] = valueMap[CxJobResult.NONE] + " " + CxJobResult.NONE

            this.jobStatusChart.datasets[0].data[0] = valueMap[CxJobStatus.CREATED]
            this.jobStatusChart.datasets[0].data[1] = valueMap[CxJobStatus.PENDING]
            this.jobStatusChart.datasets[0].data[2] = valueMap[CxJobStatus.PROCESSING]
            this.jobStatusChart.datasets[0].data[3] = valueMap[CxJobStatus.CANCELLED]
            this.jobStatusChart.datasets[0].data[4] = valueMap[CxJobStatus.DONE]

            this.jobStatusChart.labels[0] = valueMap[CxJobStatus.CREATED] + " " + CxJobStatus.CREATED
            this.jobStatusChart.labels[1] = valueMap[CxJobStatus.PENDING] + " " + CxJobStatus.PENDING
            this.jobStatusChart.labels[2] = valueMap[CxJobStatus.PROCESSING] + " " + CxJobStatus.PROCESSING
            this.jobStatusChart.labels[3] = valueMap[CxJobStatus.CANCELLED] + " " + CxJobStatus.CANCELLED
            this.jobStatusChart.labels[4] = valueMap[CxJobStatus.DONE] + " " + CxJobStatus.DONE
        },
        onRestartJob(jobId: number) {
            this.$store.dispatch("schedule/restartJob", {
                scheduleId: this.entity.id,
                jobId: jobId
            })
                .then(() => {
                    this.$cx.success(this.$t('job', 'restarted'), '')
                    this.initStats()
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("error")));
        },
        isRestartVisible(job: any) {
            if (!this.$auth.permits(this.$perm.SCHEDULER_JOB, this.$lvl.APPROVAL)) return false
            if (job.status == CxJobStatus.PENDING || job.status == CxJobStatus.PROCESSING) return false
            if (job.result == 'ERROR') return true
            return false
        },
        onAutoScrollChanged() {
            clearInterval(this.autoScrollInterval)
            if (this.autoScroll)
                this.autoScrollInterval = setInterval(() => {
                    this.scrollOutput()
                }, 100)
        },
        refreshTree() {
            if (this.apiData.jobs == null) return

            var devices = {} as any
            var nodes = {} as { [key: string]: any }

            this.deviceCount = 0

            this.apiData.jobs!.map((l: ScheduleJobDto) => {
                let key = (this.isDeviceRoleTree) ? l.deviceRoleName! : l.locationName!
                if (!(key in nodes))
                    nodes[key] = {
                        key: 'l_' + l.locationId!,
                        id: l.locationId!,
                        label: key,
                        children: [],
                        result: 'NONE',
                        isDevice: false
                    }
                if (!this.$cx.isNullOrEmpty(l.locationDeviceName)) {
                    let device = {
                        key: 'ld_' + l.locationDeviceId,
                        id: l.locationDeviceId!,
                        label: l.locationDeviceName,
                        isDevice: true,
                        jobId: l.jobId,
                        result: l.jobResult || 'NONE',
                        status: l.jobStatus || 'EMPTY'
                    }
                    nodes[key].children.push(device)
                    devices[device.id] = device

                    this.deviceCount++
                }
            })

            let res = Object.values(nodes)
            res.forEach((l: any) => {
                let errorCount = 0
                let successCount = 0
                l.children.forEach((c: any) => {
                    switch (c.result) {
                        case CxJobResult.ERROR:
                            errorCount++
                            break;
                        case CxJobResult.SUCCESS:
                            successCount++
                            break;
                    }
                })

                if (errorCount > 0)
                    l.result = 'ERROR'
                else if (successCount > 0 && successCount < l.children.length)
                    l.result = 'WARN'
                else if (successCount > 0 && successCount >= l.children.length)
                    l.result = 'SUCCESS'
                else
                    l.result = 'NONE'
            })

            this.treeNodes = res
            this.computeCharts()

            if (!this.initialExpand) {
                this.$nextTick(() => {
                    this.expandAll()
                })
                this.initialExpand = true
            }
        }

    }
});
