import { App } from "vue";
import { Router } from "vue-router";

class CxUtils {
  app!: App;
  isDebug: Boolean = false;
  constructor(app?: App) {
    if (app != null) this.app = app;
    this.isDebug = ((window as any).env?.VUE_APP_IS_DEBUG || process.env.VUE_APP_IS_DEBUG) == 1;
  }

  deepClone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  cleanPath(value: string[]) {
    return value.map((v: string) => {
      if (v.startsWith("\\")) return v.substring(1, v.length);
      return v;
    });
  }

  /**
   * Navigate to a record that needs approval on a site
   */
  goToApproval(name: string, id: string) {
    this.app.config.globalProperties.$router.push({ name: name, params: { id: id }, query: { approval: "true" } })
  }

  goToApprovalNewTab(name: string, id: string) {
    let routeData = this.app.config.globalProperties.$router.resolve({ name: name, params: { id: id }, query: { approval: "true" } });
    window.open(routeData.href, "_blank");
  }

  goTo(name: string) {
    this.app.config.globalProperties.$router.push({ name: name });
  }
  goToByIdNewTab(name: string, id: number) {
    let routeData = this.app.config.globalProperties.$router.resolve({
      name: name,
      params: {
        id: id,
      },
    });
    window.open(routeData.href, "_blank");
  }
  goToNewTab(name: string) {
    let routeData = this.app.config.globalProperties.$router.resolve({
      name: name,
    });
    window.open(routeData.href, "_blank");
  }
  goToNewTabWithArgs(name: string, data: any) {
    let routeData = this.app.config.globalProperties.$router.resolve({
      name: name,
      query: data
    });
    window.open(routeData.href, "_blank");
  }
  goToById(name: string, id: number) {
    this.app.config.globalProperties.$router.push({
      name: name,
      params: {
        id: id,
      },
    });
  }
  createUid() {
    return ("000000000" + Math.random().toString(36).substring(2, 9)).slice(-9);
  }
  toDate(date: string) {
    if (date == null) return "";
    if (date.length < 1) return "";
    return new Date(date).toLocaleDateString();
  }
  toTime(date: string | undefined) {
    if (date == null) return "";
    if (date.length < 1) return "";
    let d = new Date(date);
    return d.toLocaleDateString() + " " + d.toLocaleTimeString();
  }
  toCommaSeparated(arr: any[], key: string) {
    console.log(arr)
    if (arr == null) return ''
    if (arr.length < 1) return ''
    if (!(key in arr[0])) return ''
    return arr.map(a => a[key]).join(',')
  }

  success(title: string, message: string) {
    this.app.config.globalProperties.$toast.removeAllGroups();
    this.app.config.globalProperties.$toast.add({
      severity: "success",
      summary: title,
      detail: message,
      life: 1000,
    });
  }
  warn(title: string, message: string, time?: number) {
    this.app.config.globalProperties.$toast.removeAllGroups();
    this.app.config.globalProperties.$toast.add({
      severity: "warn",
      summary: title,
      detail: message,
      life: time || 5000,
    });
  }
  error(error: any, title?: string, message?: string) {
    this.app.config.globalProperties.$toast.removeAllGroups();
    let err = error?.response?.data?.message || error?.response?.data || message || error
    this.app.config.globalProperties.$toast.add({ severity: 'error', summary: title || this.c('error'), detail: err, life: 10000 })
    return err
  }
  generateColors(n: number) {
    const startColor = [0, 157, 220];
    const endColor = [132, 4, 0];

    if (n <= 0) return [];

    const stepSize = startColor.map((start, i) => (endColor[i] - start) / (n - 1));

    return Array.from({ length: n }, (_, i) => {
      const color = startColor.map((start, j) => Math.round(start + i * stepSize[j]));
      return `#${color.map(c => c.toString(16).padStart(2, '0')).join('')}`;
    });
  }

  getState(entity: any) {
    return JSON.parse(JSON.stringify(entity));
  }
  compareState(entitySource: any, entityTarget: any) {
    return JSON.stringify(entitySource) == JSON.stringify(entityTarget);
  }
  mapEnum(en: any) {
    let map = {} as { [key: string]: string };
    for (let value in en) {
      map[value] = value;
    }
    return map;
  }
  mapEnumToOptions(en: any) {
    let map = this.mapEnum(en);
    return Object.keys(map).map((m: string) => ({ label: m, value: m }));
  }
  mapEnumToKvp<T>(en: any) {
    let map = {} as { [key: string]: T };
    let entries = Object.entries(en).map(([key, value]) => ({ key, value }));
    for (let i = entries.length / 2; i < entries.length; i++)
      map[entries[i].key] = <T>entries[i].value;
    return map;
  }

  isNullOrEmpty(s?: string): boolean {
    if (s == null) return true;
    if (s.length < 1) return true;
    return false;
  }

  /**
   * Notifies the user about validation errors on the form
   * @param v$ the validator instance
   * @returns true if errors exist, false if no errors exist
   */
  async notifyValidationError(v$: any) {
    let result = await v$.$validate();

    if (!result) {
      let errors = v$.$silentErrors || v$.$errors || [];
      let errorLabel = errors
        .map((e: any) => e.$message.replace("{len}", ""))
        .join("\n");
      this.warn(this.e("validating"), errorLabel);
    }

    return !result;
  }

  /**
   * Notifies the user about unsaved changes
   * @param $confirm the global confirm dialog instance
   * @param nextFn the next() from the router hooks
   * @param entitySource first entity to compare
   * @param entityTarget second entity to compare
   */
  notifyUnsavedChanges(nextFn: any, entitySource: any, entityTarget: any) {
    if (!this.compareState(entitySource, entityTarget)) {
      this.app.config.globalProperties.$confirm.require({
        message: this.c('unsavedMessage'),
        header: this.c('unsavedHeader'),
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          nextFn();
        },
      });
    } else {
      nextFn();
    }
  }

  /**
   * Notifies the user about unsaved changes on multiple states
   * @param $confirm the global confirm dialog instance
   * @param nextFn the next() from the router hooks
   * @param entitySources first entities to compare - by array index
   * @param entityTargets second entities to compare - by array index
   */
  notifyUnsavedChangesMultiple(
    nextFn: any,
    entitySources: any[],
    entityTargets: any[]
  ) {
    let states = [];
    for (let i = 0; i < entitySources.length; i++)
      states.push(!this.compareState(entitySources[i], entityTargets[i]));
    let detectedChanges = states.filter((s) => s);

    if (detectedChanges != null && detectedChanges.length > 0) {
      this.app.config.globalProperties.$confirm.require({
        message:
          this.c('unsavedMessage'),
        header: this.c('unsavedHeader'),
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          nextFn();
        },
      });
    } else {
      nextFn();
    }
  }

  /**
   * Asks the user to confirm the deletion of a record
   * @param nextFn the method to execute on confirm
   */
  confirmDelete(nextFn: any) {
    this.app.config.globalProperties.$confirm.require({
      message: this.t("confirmDelete", "message1"),
      header: this.t("confirmDelete", "header1"),
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        nextFn();
      },
    });
  }

  /**
   * Asks the user to confirm the deletion of a record
   * @param name the name to display in text
   * @param nextFn the method to execute on confirm
   */
  confirmDeleteDetail(name: String, nextFn: any) {
    this.app.config.globalProperties.$confirm.require({
      message: this.tF("confirmDelete", "message", [name]),
      header: this.tF("confirmDelete", "header", [name]),
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        nextFn();
      },
    });
  }

  confirm(title: string, text: string, confirmFn: any, cancelFn?: any) {
    this.app.config.globalProperties.$confirm.require({
      message: text,
      header: title,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        confirmFn();
      },
      reject: () => {
        if (cancelFn != null) cancelFn();
      },
    });
  }

  /**
   * Returns the title of an editor page
   * @param editing switches between edit and create title
   * @param name name of entity for placeholder
   * @returns the title string
   */
  editorTitle(editing: boolean, name: string) {
    if (editing) return this.t(name, "edit")
    return this.t(name, "create")
  }

  /**
   * Notifies the user if an entity has been created successfully
   * @param entityName name of entity for placeholder
   */
  notifyCreated(entityName: string) {
    this.success(
      this.tF("notify", "created", [entityName]),
      this.tF("notify", "created1", [entityName])
    );
  }

  /**
   * Notifies the user if an entity has been saved successfully
   * @param entityName name of entity for placeholder
   */
  notifySaved(entityName: string) {
    this.success(
      this.t("notify", "saved"),
      this.tF("notify", "saved1", [entityName])
    );
  }

  /**
   * Notifies the user if an entity has been deleted
   * @param entityName name of entity for placeholder
   */
  notifyDeleted(entityName: string) {
    this.success(
      this.tF("notify", "deleted", [entityName]),
      this.tF("notify", "deleted1", [entityName])
    );
  }

  /**
   * Notifies the user if an entity has been saved successfully
   * @param entityName name of entity for placeholder
   */
  notifyEditorUploaded(entityName: string) {
    this.success(
      this.t("notify", "uploaded"),
      this.tF("notify", "uploaded1", [entityName])
    );
  }

  /**
   * Notifies the user if an entity has been saved successfully
   * @param entityName name of entity for placeholder
   */
  notifyImportChecked(entityName: string) {
    this.success(
      this.t("notify", "importCheck"),
      this.tF("notify", "importCheck1", [entityName])
    );
  }

  /**
   * Notifies the user with a generic one
   * @param title the title to display
   * @param message the message to display
   */
  notifyGeneric(title: string, message: string) {
    this.success(
      title,
      message
    );
  }

  r(key: string) {
    if ((key in (this.app.config.globalProperties.$primevue as any).config.locale["common"])) {
      return (this.app.config.globalProperties.$primevue as any).config.locale["common"][key]
    } else if ((key in (this.app.config.globalProperties.$primevue as any).config.locale)) {
      return (this.app.config.globalProperties.$primevue as any).config.locale[key]["lb"]
    }

    console.log(`missing locale: validation ${key}`)

    return ""
  }

  t(entity: string, key: string) {
    if (!(entity in (this.app.config.globalProperties.$primevue as any).config.locale)) {
      console.log(`missing locale: ${entity}.${key}`)
      return ""
    }

    if (!(key in (this.app.config.globalProperties.$primevue as any).config.locale[entity])) {
      console.log(`missing locale: ${entity}.${key}`)
      return ""
    }

    return (this.app.config.globalProperties.$primevue as any).config.locale[entity][key]
  }

  c(key: string) {
    return this.t("common", key)
  }

  e(key: string) {
    return this.t("error", key)
  }

  /**
   * Returns a string by name from the locale files (de.json, en.json, fr.json)
   * and replaces the placeholders by format strings
   * @param localeName the name of the locale item
   * @param formatStrings the placeholder values
   * @returns the translated string
   */
  tF(entity: string, key: string, formatStrings: any[]) {
    let s = this.t(entity, key);
    for (let i = 0; i < formatStrings.length; i++) {
      s = s.replace("{" + i + "}", formatStrings[i]);
    }
    return s;
  }

  /**
   * Gets an item from localStorage
   * @param name the item to get
   * @param defaultValue the default value if the item is null
   * @returns the item value as a number or the default value if item is null
   */
  getStorage(name: string, defaultValue: string) {
    let item = localStorage.getItem(name);
    if (item == null) return defaultValue;
    return item;
  }

  /**
   * Gets an item as boolean from localStorage
   * @param name the item to get
   * @param defaultValue the default value if the item is null
   * @returns the item value as a number or the default value if item is null
   */
  getStorageBool(name: string, defaultValue: boolean) {
    let item = localStorage.getItem(name);
    if (item == null) return defaultValue;
    if (item == "true") return true;
    return false;
  }

  /**
   * Gets an item as Integer from localStorage
   * @param name the item to get
   * @param defaultValue the default value if the item is null
   * @returns the item value as a number or the default value if item is null
   */
  getStorageInt(name: string, defaultValue: number) {
    let item = localStorage.getItem(name);
    if (item == null) return defaultValue;
    return parseInt(item);
  }

  /**
   * Removes an object from an array by property-key.
   * There is no return value, the original array gets mutated
   * @param arr the array to remove the object from
   * @param obj the object to remove
   * @param byKey the property-name to compare
   */
  removeFrom(arr: any[], obj: any, byKey: string) {
    let index = arr
      .map((x) => {
        return x[byKey];
      })
      .indexOf(obj[byKey]);
    arr.splice(index, 1);
  }

  isEqual(obj1: any, obj2: any) {
    return JSON.stringify(obj1) == JSON.stringify(obj2);
  }

  /**
   * Removes a range of items from a target array
   * @param target the target array to check against
   * @param itemsToRemove the items to remove
   * @returns a copy of the array with the filter applied
   */
  removeRange(target: any[], itemsToRemove: any[]) {
    let arr = [];
    let removeItems = itemsToRemove.map((i) => JSON.stringify(i));
    for (const element of target) {
      if (!removeItems.includes(JSON.stringify(element))) arr.push(element);
    }
    return arr;
  }

  getWeekday(time: Date) {
    if (time == null) return "";
    return (this.app.config.globalProperties.$primevue as any).config.locale.dayNames[time.getDay()]
    /*switch (time.getDay()) {
      case 0:
        return "Sonntag";
      case 1:
        return "Montag";
      case 2:
        return "Dienstag";
      case 3:
        return "Mittwoch";
      case 4:
        return "Donnerstag";
      case 5:
        return "Freitag";
      case 6:
        return "Samstag";
    }*/
  }

  getMonth(time: Date) {
    if (time == null) return "";
    return (this.app.config.globalProperties.$primevue as any).config.locale.monthNames[time.getMonth()]
    /*switch (time.getMonth()) {
      case 0:
        return "Januar";
      case 1:
        return "Februar";
      case 2:
        return "März";
      case 3:
        return "April";
      case 4:
        return "Mail";
      case 5:
        return "Juni";
      case 6:
        return "Juli";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "Oktober";
      case 10:
        return "November";
      case 11:
        return "Dezember";
    }*/
  }

  leadingZero(n: number) {
    if (n < 10) return "0" + n;
    return n;
  }

  /**
   * Checks if an array contains a value by property key
   * @param value the value to check (1)
   * @param arr the array to check ([{}, {}])
   * @param property the property name to check: "id"
   * @returns true or false
   */
  contains(value: any, arr: any[], property: string) {
    return arr.some((o) => o[property] == value);
  }

  tryGetObjectValue(key: string, obj: any) {
    return (key in obj && obj[key] != null && obj[key].toString().length > 0)
  }

  /**
   * Returns the next valid readable key for display as an array node inside the var-tree
   */
  getReadableTreeArrayIndex(index: number, node: any) {
    if (this.tryGetObjectValue("type", node) && this.tryGetObjectValue("name", node))
      return `${index}: ${node.type} ${node.name}`
    if (this.tryGetObjectValue("name", node)) return `${index}: ${node.name}`
    if (this.tryGetObjectValue("type", node)) return `${index}: ${node.type}`
    return index
  }

  mapObjectToTree(obj: any, parentNode: any) {
    let nodes = [] as any;

    for (let key in obj) {
      let node = {
        key: this.createUid(),
        label: "",
        value: "",
        children: [] as any,
      };

      node.label = `${key}`;

      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        node.children = this.mapObjectToTree(obj[key], node);
        if (node.children.length < 1) {
          node.value = "null"
          // node.label += " = null";
        }
        nodes.push(node);
      } else if (Array.isArray(obj[key])) {
        node.label += " []";
        obj[key].forEach((e: any, i: any) => {
          node.children.push({
            key: this.createUid(),
            label: this.getReadableTreeArrayIndex(i, e),
            children: this.mapObjectToTree(e, node),
          });
        });
        nodes.push(node);
      } else {
        if (this.isNullOrEmpty(obj[key])) {
          node.value = "null"
          //node.label += " = null";
        }
        else {
          node.value = obj[key]
          // node.label += " = " + obj[key];
        }
        nodes.push(node);
      }
    }

    return nodes;
  }

  ternary(expression: boolean, trueValue: string, falseValue: string) {
    return expression ? trueValue : falseValue
  }

  isObject(v: any) {
    if (v == null) return false
    return typeof v === 'object'
  }

  isArray(v: any) {
    if (v == null) return false
    return v.constructor === Array
  }

  isApprovalRoute(router: Router) {
    return router.currentRoute.value.query["approval"]?.toString() == "true"
  }

  /**
    * Compares and returns the differences between two objects
    */
  deltaChanges(initialState: any, targetState: any) {
    let changes = {} as any
    if (targetState == null) return
    Object.keys(targetState).forEach(key => {

      // if the value is a nested object, compare the objects with eachother
      if (this.isObject(targetState[key])) {

        // if the value is an array, simply compare the ids
        if (this.isArray(targetState[key])) {
          let oldIds = initialState[key].map((i: any) => i?.id)
          let newids = targetState[key].map((i: any) => i?.id)
          if (oldIds.toString() != newids.toString())
            changes[key] = {
              oldValue: initialState[key],
              newValue: targetState[key]
            }
        }

        if (initialState[key]?.id != targetState[key]?.id) {
          changes[key] = {
            oldValue: initialState[key],
            newValue: targetState[key]
          }
        }

        // do nothing on Audit-Fields
      } else if (key == 'changedAt'
        || key == 'changedBy'
        || key == 'createdAt'
        || key == 'createdBy') {


        // otherwise do a simple not equals check
      } else if (initialState[key] != targetState[key]) {
        changes[key] = {
          oldValue: initialState[key],
          newValue: targetState[key]
        }
      }
    })

    return changes
  }
}

/* Vue 3 type augmentation and cx plugin installation */

const cxUtils = new CxUtils();

export default {
  install: (app: App) => {
    app.config.globalProperties.$cx = cxUtils;
    app.config.globalProperties.$t = cxUtils.t.bind(cxUtils);
    app.config.globalProperties.$c = cxUtils.c.bind(cxUtils);
    cxUtils.app = app;
  },
};

type ITranslate = (entity: string, key: string) => any
type ITranslateCommon = (key: string) => any


// @ts-ignore
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $cx: CxUtils;
    $t: ITranslate;
    $c: ITranslateCommon;
  }
}

export { cxUtils };
