
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        store: {
            type: String,
            default: ""
        },
        storeMethod: {
            type: String,
            default: ""
        },
        header: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            id: null as any,
            isVisible: false,
            relations: [] as any[],
            callbackFn: null as any,
            isLoading: false
        };
    },
    methods: {
        onDelete(id: any, callbackFn: any) {
            this.id = id
            this.isVisible = true
            this.callbackFn = callbackFn
            let storeMethod = "/deleteWithConfirm"
            if (!this.$cx.isNullOrEmpty(this.storeMethod))
                storeMethod = this.storeMethod

            this.$store
                .dispatch(this.store + storeMethod, { id: id, confirm: false })
                .then((data) => {
                    this.relations = data
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
        },
        routeToEntity(type: any, id: any): any {
            let route = ""
            switch (type) {
                case "LocationDevice":
                    route = "locationDeviceEditor"
                    break;
                case "Configurations":
                    route = "locationDeviceEditor"
                    break;
                case "Schedules":
                    route = "locationDeviceEditor"
                    break;
                case "CiscoPNP":
                    route = "locationDeviceEditor"
                    break;
                case "Serials":
                    route = "locationDeviceEditor"
                    break;
                case "DeviceTemplates":
                    route = "deviceTemplateEditor"
                    break;
                case "Variables":
                    route = "variableEditor"
                    break;
                case "Location":
                    route = 'locationEditor'
                    break;
                case "DeviceModel":
                    route = 'deviceModelEditor'
                    break;
                case "DeviceRole":
                    route = 'deviceRoleEditor'
                    break;
                case "DeviceConfigTemplates":
                    route = 'configurationEditor'
                    break;
                case "LocationTypes":
                    route = 'locationTypeEditor'
                    break;
                case "LocationCategory":
                    route = 'locationCategoryEditor'
                    break;
                case "LocationCategoryRole":
                    route = 'locationCategoryEditor'
                    break;
                case "LocationCategoryRoleConnection":
                    route = 'locationCategoryEditor'
                    break;
                case "SoftwareVersion":
                    route = 'locationDeviceEditor'
                    break;
                case "ConfigurationTemplates":
                    route = 'configurationEditor'
                    break;
                case "Vlans":
                    route = 'virtualNetworkEditor'
                    break;
                case "VirtualNetwork":
                    route = 'virtualNetworkEditor'
                    break;
                case "LocationDeviceIp":
                    route = 'locationDeviceEditor'
                    break;
                case "CatalystCenter":
                    window.open(id, "_blank");
                    return
            }
            this.$cx.goToByIdNewTab(route, parseInt(id))
        },
        onCancel() {
            this.isVisible = false
        },
        onConfirmDelete() {
            let storeMethod = "/deleteWithConfirm"
            if (!this.$cx.isNullOrEmpty(this.storeMethod))
                storeMethod = this.storeMethod

            this.isLoading = true
            this.$store
                .dispatch(this.store + storeMethod, { id: this.id, confirm: true })
                .then(() => {
                    this.isVisible = false
                    this.callbackFn()
                })
                .finally(() => {
                    this.isLoading = false
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
        }
    }
})
