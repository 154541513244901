
import {defineComponent} from 'vue';

export default defineComponent({
  props: {
    deviceModelId: String,
  },
  data() {
    return {
      bugs: [],
      totalRecords: 0,
      totalPages: 0,
      loading: false,
      filters: {
        page: 0,
        size: 20,
        modelId: this.deviceModelId
      },
      customPaginatorTemplate: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
      currentPageReportTemplate: "{first} - {last} of {totalRecords} records | Page {currentPage} of {totalPages}",
      descriptionDialogVisible: false,
      fullDescription: '',
    };
  },
  mounted() {
    this.loadBugs();
  },
  methods: {
    loadBugs() {
      this.loading = true;
      this.$store
      .dispatch("bugApi/getBugsForDeviceModel", this.filters)
      .then((bugsData) => {
        this.bugs = bugsData;
      })
      .catch((error) => this.$cx.error(error, this.$cx.e("loading")))
      .finally(() => {
        this.loading = false;
      });
    },
    onPageChange(event: any) {
      this.filters.page = event.page;
      this.loadBugs();
    },
    shortenDescription(description: string) {
      return description.length > 25
          ? description.slice(0, 25) + this.$t("ciscoBug", "descriptionShortened")
          : description;
    },
    showFullDescription(description: string) {
      this.fullDescription = description;
      this.descriptionDialogVisible = true;
    },
  }
});
