/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.30.840 on 2024-10-24 15:05:13.

export class CxPermissionBuilder {
    cxPermissionString?: string;
}

export class BaseDto {
    id?: number;
    createdAt?: Date;
    changedAt?: Date;
    createdBy?: string;
    changedBy?: string;
    locked?: boolean;
}

export class ApiParamDto extends BaseDto {
    url?: string;
    type?: CxApiType;
    username?: string;
    password?: string;
    name?: string;
}

export class AppContextDto extends BaseDto {
    user?: UserRepresentationDto;
    props?: CxApplicationProps;
}

export class CxApplicationProps {
    approvalEntities?: { [P in CxEntityType]?: boolean };
    apiUrl?: string;
    ztpUrl?: string;
    osUrl?: string;
}

export class ApprovalDto extends BaseDto {
    entityId?: number;
    entityType?: CxEntityType;
    state?: CxApprovalState;
    data?: any;
    comment?: string;
}

export class LoginProfileDto extends BaseDto {
    name?: string;
    loginType?: CxLoginType;
    username?: string;
    password?: string;
    default?: boolean;
}

export class RoleDto extends BaseDto {
    name?: string;
    roleName?: string;
    permissions?: string;
}

export class RoleRepresentationDto {
    id?: string;
    name?: string;
    description?: string;
    attributes?: { [index: string]: string[] };
}

export class UpdateRoleRequest {
    oldName?: string;
    newName?: string;
    permissions?: string;
}

export class UserRepresentationDto {
    id?: string;
    email?: string;
    username?: string;
    firstname?: string;
    lastname?: string;
    isEmailNotificationEnabled?: boolean;
    roles?: RoleRepresentationDto[];
    attributes?: { [index: string]: string[] };
}

export class CxPnpResponse {
    correlator?: string;
    udi?: string;
    success?: string;
    errorCode?: string;
    errorMessage?: string;
    serviceLog?: string;
}

export class CxPnpScope {
    correlator?: string;
    udi?: string;
    serialNumber?: string;
    hostname?: string;
    authRequired?: string;
    viaProxy?: string;
    securityAdvise?: string;
    configUrl?: string;
    fileName?: string;
    checksum?: string;
    type?: string;
}

export class CxPnpUdi {
    productId?: string;
    versionId?: string;
    serialNumber?: string;
}

export class CiscoAciDto extends BaseDto {
    groupRef?: string;
    hostname?: string;
    ipAddress?: string;
    protocol?: string;
    username?: string;
    password?: string;
}

export class CiscoAciQueryRequest {
    aciId?: number;
    templateName?: string;
    props?: { [index: string]: string };
}

export class CatalystCenterDto extends BaseDto {
    siteCreated?: boolean;
    apiParam?: ApiParamDto;
    site?: CatalystCenterSite;
}

export class CiscoMerakiDeviceDto {
    token?: string;
    networkId?: string;
    serials?: string[];
}

export class CiscoMerakiNetworkDto {
    name?: string;
    token?: string;
    organizationId?: string;
    productTypes?: string[];
}

export class CiscoMerakiRequest<T> {
    templateName?: string;
    merakiDto?: T;
}

export class BaseEntity {
    id?: number;
    createdBy?: string;
    changedBy?: string;
    createdAt?: Date;
    changedAt?: Date;
    deleted?: boolean;
    locked?: boolean;
}

export class CiscoPnpRequest extends BaseEntity {
    correlator?: string;
    fileName?: string;
    successful?: boolean;
    serialNumber?: string;
    locationDevice?: LocationDevice;
}

export class CiscoPnpRequestDto extends BaseDto {
    correlator?: string;
    fileName?: string;
    serialNumber?: string;
    successful?: boolean;
    locationDevice?: LocationDeviceDto;
}

export class LogZTPStatusDto {
    logId?: number;
    locationId?: number;
    locationDeviceId?: number;
    locationName?: string;
    locationDeviceName?: string;
    status?: string;
    version?: string;
    serialNumber?: string;
    createdAt?: string;
}

export class ZtpLogDto extends BaseDto {
    logMessage?: string;
    serialNumber?: SerialNumberDto;
}

export class ComplianceDto extends BaseDto {
    name?: string;
    valid?: boolean;
    rules?: RuleDto[];
}

export class CxComplianceError {
    line?: number;
    text?: string;
    missingInSource?: boolean;
    missingInTarget?: boolean;
}

export class CxComplianceMatchPair {
    errors?: CxComplianceError[];
    source?: CxConfigNode;
    target?: CxConfigNode;
    selector?: SelectorDto;
    compliant?: boolean;
}

export class CxComplianceResult {
    matches?: CxComplianceMatchPair[];
}

export class CxConfigNode {
    line?: number;
    level?: number;
    text?: string;
    children?: CxConfigNode[];
}

export class RuleDto extends BaseDto {
    name?: string;
    selectors?: SelectorDto[];
    blacklist?: SelectorDto[];
    deviceFamilyGroup?: DeviceFamilyGroup;
}

export class SelectorDto extends BaseEntity {
    name?: string;
    text?: string;
    blacklist?: boolean;
}

export class ConfigurationDto extends BaseDto {
    filename?: string;
    version?: string;
    text?: string;
    status?: string;
    source?: string;
}

export class CxConfigDto extends BaseDto {
    name?: string;
    weight?: number;
    configurationText?: string;
    noConfigurationText?: string;
    manufacturer?: ManufacturerDto;
    roles?: DeviceRoleDto[];
    models?: DeviceModelDto[];
    families?: DeviceFamilyDto[];
    templates?: DeviceTemplateDto[];
    locations?: LocationDto[];
    locationTypes?: LocationTypeDto[];
    locationDevices?: LocationDeviceDto[];
    locationCategories?: LocationCategoryDto[];
}

export class CxConfigDtoBuilder {
}

export class StateDiffDto<T> {
    entitiesToAdd?: T[];
    entitiesToUpdate?: T[];
    entitiesToRemove?: T[];
}

export class TemplateDto {
    text?: string;
    parameters?: { [index: string]: any };
}

export class CodeTemplateDto extends BaseDto {
    name?: string;
    code?: string;
    internal?: boolean;
    requirements?: string;
    type?: CxCodeTemplateType;
}

export class CxConfigScope {
    hostname?: string;
    managementIp?: string;
    meta?: any;
    stack?: CxStackMember;
    profile?: CxLoginProfile;
    vrfs?: CxConfigVrf[];
    vlans?: CxConfigVlan[];
    ports?: CxConfigPort[];
    logicalPorts?: CxConfigPort[];
}

export class CxCodeTemplateScope extends CxConfigScope {
    deviceId?: number;
    apiUrl?: string;
    workerToken?: string;
    codeTemplateName?: string;
    osUpdate?: CxOsUpdate;
}

export class CxConfig {
    id?: number;
    name?: string;
    weight?: number;
    configurationText?: string;
    noConfigurationText?: string;
    manufacturer?: ManufacturerDto;
    roles?: DeviceRole[];
    models?: DeviceModel[];
    families?: DeviceFamily[];
    templates?: DeviceTemplate[];
    locations?: Location[];
    locationTypes?: LocationType[];
    locationDevices?: LocationDevice[];
    locationCategories?: LocationCategory[];
    createdBy?: string;
    changedBy?: string;
    createdAt?: Date;
    changedAt?: Date;
}

export class CxConfigBuilder {
}

export class CxConfigOptions {
    hideSensitiveInfo?: boolean;
    saveCompiledResult?: boolean;
    stackMemberOverride?: CxStackMember;
}

export class CxConfigPart {
    text?: string;
    configId?: number;
    configName?: string;
    configWeight?: number;
}

export class CxConfigPort {
    type?: string;
    name?: string;
    portDesc?: string;
    templDesc?: string;
    ip?: string;
    subnet?: string;
    meta?: any;
    vrf?: CxConfigVrf;
    vlan?: CxConfigVlan;
}

export class CxConfigResult {
    result?: CxConfigPart[];
    source?: CxConfigPart[];
    variables?: { [index: string]: any };
    configurationText?: string;
}

export class CxConfigState {
    id?: number;
    name?: string;
    weight?: number;
    sourceText?: string;
    compiledText?: string;
}

export class CxConfigVlan {
    name?: string;
    vlanId?: number;
    hsrpGroup?: string;
    configurationText?: string;
    ip?: string;
    subnet?: string;
    meta?: any;
}

export class CxConfigVrf {
    asn?: string;
    name?: string;
    vpnNumber?: string;
    description?: string;
    ip?: string;
    subnet?: string;
    meta?: any;
}

export class CxLoginProfile {
    name?: string;
    loginType?: CxLoginType;
    username?: string;
    password?: string;
}

export class CxOsUpdate {
    filename?: string;
    checksum?: string;
    url?: string;
    osId?: number;
}

export class DashboardChartDto {
    id?: number;
    count?: number;
    name?: string;
}

export class DashboardStatsDto {
    locationCount?: number;
    locationDeviceCount?: number;
    ipPoolCount?: number;
    ipPoolSubnetCount?: number;
    vrfCount?: number;
    vlanCount?: number;
    variableCount?: number;
    variableValueCount?: number;
    configCount?: number;
}

export class SoftwareComplianceDto {
    deviceModelName?: string;
    manufacturerName?: string;
    valid?: number;
    invalid?: number;
    unknown?: number;
}

export class SoftwareManagementDto {
    locationId?: number;
    locationName?: string;
    locationDeviceId?: number;
    locationDeviceName?: string;
    managementIp?: string;
    deviceRoles?: IdNameDto[];
    deviceFamilies?: IdNameDto[];
    deviceModels?: IdNameDto[];
    osId?: number;
    sourceVersion?: string;
    targetVersion?: string;
    valid?: boolean;
}

export class LocationDeviceDto extends BaseDto {
    name?: string;
    location?: LocationDto;
    deviceRole?: IdNameDto;
    deviceTemplate?: DeviceTemplateDtoFlat;
    osName?: string;
    managementIp?: string;
    ztpContactTime?: Date;
    loginProfile?: IdNameDto;
    meta?: any;
}

export class CreateLocationDeviceRequest extends LocationDeviceDto {
    serials?: string[];
}

export class LocationDeviceIpDto extends BaseDto {
    ip?: string;
    subnet?: string;
    description?: string;
    meta?: any;
    vlan?: VlanDto;
    devicePort?: DevicePortDto;
    locationDevice?: LocationDeviceDto;
    virtualNetwork?: VirtualNetworkDto;
    logicalInterface?: LogicalInterfaceDto;
}

export class LocationDeviceStateDto {
    locationDeviceId?: number;
    state?: string;
    cmState?: string;
    onbState?: string;
    validActions?: { [index: string]: any };
}

export class LocationDeviceTreeDto {
    id?: number;
    name?: string;
    locationId?: number;
    ztpStatus?: string;
    ztpContactTime?: Date;
    locationName?: string;
    filename?: string;
    serials?: string;
}

export class LocationDeviceViewDto {
    id?: number;
    name?: string;
    osName?: string;
    deviceRole?: { [index: string]: any };
    deviceTemplate?: { [index: string]: any };
    state?: LocationDeviceStateDto;
}

export class PortResponseDto {
    id?: number;
    name?: string;
    mode?: string;
}

export class DeviceConfigTemplateDto extends BaseDto {
    name?: string;
    weight?: number;
    configurationText?: string;
    noConfigurationText?: string;
    manufacturer?: ManufacturerDto;
}

export class DeviceConfigTemplateRelDto extends BaseDto {
    locations?: LocationDto[];
    locationTypes?: LocationTypeDto[];
    locationCategories?: LocationCategoryDto[];
    deviceRoles?: DeviceRoleDto[];
    deviceModels?: DeviceModelDto[];
    deviceFamilies?: DeviceFamilyDto[];
    deviceTemplate?: DeviceTemplateDto[];
    deviceConfigTemplate?: DeviceConfigTemplateDto[];
}

export class RunningConfigDto {
    runningConfigText?: string;
}

export class DeviceFamilyDto extends BaseDto {
    name?: string;
    manufacturer?: ManufacturerDto;
}

export class DeviceFamilyGroupDto extends BaseDto {
    name?: string;
    deviceFamilies?: IdNameDto[];
}

export class DeviceModelDto extends BaseDto {
    name?: string;
    deviceFamily?: DeviceFamilyDto;
    catalystCenterActivated?: boolean;
}

export class OSDto extends BaseDto {
    name?: string;
    version?: string;
    filename?: string;
    checksum?: string;
    path?: string;
    osImages?: OsImage[];
}

export class DevicePortDto extends BaseDto {
    name?: string;
    description?: string;
    portInterface?: string;
    devicePortType?: DevicePortTypeDto;
    deviceTemplate?: DeviceTemplateDto;
}

export class DevicePortTypeDto extends BaseDto {
    name?: string;
    description?: string;
}

export class DevicePortUseDto extends BaseDto {
    name?: string;
    description?: string;
}

export class DeviceRoleDto extends BaseDto {
    name?: string;
    description?: string;
}

export class DeviceRolePortUseDto extends BaseDto {
    count?: number;
    deviceRole?: DeviceRoleDto;
    devicePortUse?: DevicePortUseDto;
}

export class SerialNumberDto extends BaseDto {
    serialNumber?: string;
    stackOrder?: number;
    stackPriority?: number;
    locationDevice?: IdNameDto;
    deviceTemplateModel?: DeviceTemplateModelDtoFlat;
}

export class DeviceTemplateDto extends BaseDto {
    name?: string;
    description?: string;
    isStack?: boolean;
    stackOrder?: CxStackMember[];
    os?: OSDto;
    deviceRoles?: DeviceRoleDto[];
    deviceTemplateModels?: DeviceTemplateModelDtoFlat[];
}

export class DeviceTemplateModelDto extends BaseDto {
    deviceTemplate?: DeviceTemplateDto;
    deviceModel?: DeviceModelDto;
    stackOrder?: number;
    stackPriority?: number;
}

export class DeviceTemplateRoleDto extends BaseDto {
    deviceRoles?: DeviceRoleDto[];
    deviceTemplates?: DeviceTemplateDto[];
}

export class EoxRecordDto extends BaseDto {
    eolProductId?: string;
    productIdDescription?: string;
    productBulletinNumber?: string;
    linkToProductBulletinURL?: string;
    eoxExternalAnnouncementDate?: string;
    endOfSaleDate?: string;
    endOfSWMaintenanceReleases?: string;
    endOfSecurityVulSupportDate?: string;
    endOfRoutineFailureAnalysisDate?: string;
    endOfServiceContractRenewal?: string;
    lastDateOfSupport?: string;
    endOfSvcAttachDate?: string;
    updatedTimeStamp?: string;
    eoxInputType?: string;
    eoxInputValue?: string;
    eoxMigrationDetails?: EoxMigrationDetails;
}

export class EoxMigrationDetails {
    pidActiveFlag?: string;
    migrationInformation?: string;
    migrationOption?: string;
    migrationProductId?: string;
    migrationProductName?: string;
    migrationStrategy?: string;
    migrationProductInfoURL?: string;
}

export class UpdateStackOrderDto<T1> {
    stackOrderItems?: T1[];
}

export class SoftwareVersionDto {
    imageName?: string;
    version?: string;
    release?: string;
    rommon?: string;
    hostname?: string;
    runningImage?: string;
    serial?: string[];
    hardware?: string[];
    macAddress?: string[];
    locationDevice?: IdDto;
}

export class SoftwareVersionInfoDto {
    id?: number;
    version?: string;
}

export class BLBExtenderDto {
    locationId?: IdDto;
    deviceRoleId?: IdDto;
    deviceTemplateId?: IdDto;
    managementIp?: string;
    hostname?: string;
    variable?: string;
    variableValue?: string;
    serialNumbers?: string[];
}

export class CheckImportDto {
    dataObjects?: CsvRecordDto[];
    locationNames?: string[];
    deviceRoleNames?: string[];
    deviceTemplateNames?: string[];
    hostnames?: string[];
    serialNumbers?: string[];
}

export class CsvRecordDto {
    locationName?: string;
    deviceRoleName?: string;
    deviceTemplateName?: string;
    managementIp?: string;
    hostname?: string;
    variableValue?: string;
    serialNumbers?: string;
}

export class ExistsDto {
    doesExist?: NameDto[];
    doesNotExist?: NameDto[];
}

export class IdDto {
    id?: number;
}

export class NameDto extends IdDto {
    name?: string;
}

export class SerialNumberExistDto {
    serialNumber?: string;
}

export class StringExistDto {
    names?: string[];
}

export class UniversalExtenderDto extends BaseDto {
    location?: LocationDto;
    name?: string;
    deviceRole?: DeviceRoleDto;
    deviceTemplate?: DeviceTemplateDto;
    managementIp?: string;
    serialNumbers?: SerialNumberDto[];
    variableValueMap?: { [index: string]: string };
}

export class UniversalImportDto extends BaseDto {
    location?: any;
    locationCategory?: string;
    street?: string;
    buildingNumber?: string;
    postalCode?: string;
    city?: string;
    continent?: string;
    country?: string;
    state?: string;
    name?: string;
    deviceRole?: any;
    deviceTemplate?: any;
    managementIp?: string;
    manufacturer?: string;
    deviceFamily?: string;
    deviceModel?: string;
    operatingSystem?: string;
    locationTypes?: string[];
    serialNumbers?: SerialNumberExistDto[];
    variableValueMap?: { [index: string]: string };
}

export class UniversalImportResultDto {
    locationDevice?: LocationDeviceDto;
    serialNumbers?: SerialNumberDto[];
    variables?: { [index: string]: string };
}

export class VariableUniversalDto {
    variableValueMap?: { [index: string]: string };
}

export class HuaweiZtpLogDto {
    serialNumber?: string;
    message?: string;
    file?: string;
    time?: Date;
}

export class CxLocationConfigPair {
    device?: LocationDeviceDto;
    configurations?: ConfigurationDto[];
}

export class CxLocationConfigResult {
    generatedConfigForDevices?: IdNameDto[];
    errors?: CxError[];
}

export class CxLocationConfigResultBuilder {
}

export class LocationDto extends BaseDto {
    name?: string;
    city?: string;
    state?: string;
    country?: string;
    continent?: string;
    street?: string;
    postCode?: string;
    streetNumber?: string;
    catalystCenter?: IdNameDto;
    catalystCenterPath?: string;
    catalystCenterActivated?: boolean;
    locationTypes?: LocationTypeDto[];
    locationCategory?: LocationCategoryDto;
    locationDeviceRoles?: DeviceRoleDto[];
}

export class LocationStateDto {
    deviceRoleId?: number;
    deviceRoleName?: string;
    minCount?: number;
    maxCount?: number;
    locationDeviceCount?: number;
}

export class LocationSubnetDto {
    locationId?: number;
    locationName?: string;
    locationTypeId?: number;
    locationTypeName?: string;
    vlanName?: string;
    ipPoolName?: string;
    subnetIp?: string;
    subnetStartIp?: string;
    subnetEndIp?: string;
    subnetBroadcastIp?: string;
    subnetGatewayIp?: string;
    subnetPrefix?: string;
}

export class LocationCategoryDto extends BaseDto {
    name?: string;
    description?: string;
    locationType?: LocationTypeDto[];
    locationCategoryRoles?: LocationCategoryRoleDto[];
}

export class LocationCategoryRoleDto extends BaseDto {
    minCount?: number;
    maxCount?: number;
    deviceRole?: DeviceRoleDto;
    locationCategory?: LocationCategoryDto;
}

export class LocationCategoryRolePortRelDto {
    id?: number;
    sourceRoleIndex?: number;
    targetRoleIndex?: number;
    sourcePortIndex?: number;
    targetPortIndex?: number;
    sourceRole?: LocationCategoryRoleDto;
    sourcePortUse?: DeviceRolePortUseDto;
    targetRole?: LocationCategoryRoleDto;
    targetPortUse?: DeviceRolePortUseDto;
    locationCategory?: LocationCategoryDto;
}

export class LocationCategoryRoleStateDto {
    locationCategoryRoleId?: number;
    locationCategoryRoleName?: string;
    devicePortUseId?: number;
    devicePortUseName?: string;
    devicePortUseCount?: number;
    minRoleCount?: number;
    maxRoleCount?: number;
}

export class LocationTypeDto extends BaseDto {
    name?: string;
    description?: string;
    vlans?: VlanDto[];
}

export class ManufacturerDto extends BaseDto {
    name?: string;
}

export class ContactDto extends BaseDto {
    firstName?: string;
    lastName?: string;
    address?: string;
    country?: string;
    company?: string;
    email?: string;
    phoneNumber?: string;
    mobilePhoneNumber?: string;
    contactType?: ContactTypeDto;
    manufacturer?: ManufacturerDto;
}

export class ContactTypeDto extends BaseDto {
    type?: string;
}

export class IPPoolDto extends BaseDto {
    name?: string;
    networkIp?: string;
    networkIpRange?: string;
    networkPrefix?: number;
    subnetPrefix?: number;
    networkType?: CxNetworkType;
    networkProtocol?: CxNetworkProtocol;
    hostCount?: number;
    networkCount?: number;
    autoAssign?: boolean;
    vlans?: VlanDto[];
}

export class IPPoolSubnetDto {
    id?: number;
    startIp?: string;
    endIp?: string;
    subnetIp?: string;
    gatewayIp?: string;
    subnetPrefix?: number;
    broadcastIp?: string;
    isAssigned?: boolean;
    vlans?: VlanDto[];
    usedAddresses?: string[];
}

export class IPPoolVirtualNetworkDto extends BaseDto {
    vlan?: VlanDto;
    ipPool?: IPPoolDto;
    virtualNetwork?: VirtualNetworkDto;
}

export class SubnetCreateRequest {
    subnetIp?: string;
    subnetPrefix?: number;
    isAssigned?: boolean;
}

export class SubnetParamsRequest {
    count?: number;
    prefix?: number;
    vlans?: VlanDto[];
}

export class SubnetUpdateRequest {
    id?: number;
    startIp?: string;
    endIp?: string;
    subnetIp?: string;
    gatewayIp?: string;
    subnetPrefix?: number;
    broadcastIp?: string;
    isAssigned?: boolean;
    vlans?: Vlan[];
    usedAddresses?: string[];
}

export class LogicalInterfaceDto extends BaseDto {
    name?: string;
    meta?: any;
    weight?: number;
    description?: string;
    ipPool?: IPPoolDto;
    locationType?: LocationTypeDto;
}

export class VirtualNetworkDto extends BaseDto {
    asn?: string;
    meta?: any;
    name?: string;
    vpnNumber?: number;
    description?: string;
}

export class VlanDto extends BaseDto {
    name?: string;
    vlanId?: number;
    meta?: any;
    hsrpGroup?: string;
    configurationText?: string;
    virtualNetwork?: VirtualNetworkDto;
}

export class CustomerDto extends BaseDto {
    configFilters?: any[];
    configs?: string[];
    name?: string;
}

export class IdNameDto {
    id?: number;
    name?: string;
}

export class CxDeleteRelation {
    type?: string;
    name?: string;
    id?: string;
}

export class CxError {
    message?: string;
}

export class CxExporter<T> {
    filename?: string;
    byteArray?: any;
    httpHeaders?: { [index: string]: string[] };
}

export class CxFilter {
    filters?: { [index: string]: any };
}

export class CxImportResult<T> {
    line?: number;
    errors?: string[];
    dto?: T;
    successful?: boolean;
}

export class CxImportResult2<T> {
    success?: boolean;
    imported?: boolean;
    data?: { [index: string]: T };
    globalErrors?: string[];
    cellErrors?: { [index: string]: { [index: string]: string } };
}

export class CxImportRowResult<T> {
    data?: T;
    errors?: { [index: string]: string };
}

export class CxImporter<T> {
}

export class CxLogEntry {
    message?: string;
    level?: string;
    area?: string;
    time?: Date;
}

export class CxMapping {
    type?: CxMappingType;
    sourceField?: string;
    targetField?: string;
    description?: string;
}

export class CxPage<T> {
    content?: T[];
    totalPages?: number;
    totalRecords?: number;
}

export class CxProcessResult {
    output?: string;
    isSuccessful?: boolean;
}

export class CxStackMember {
    priority?: number;
    memberId?: number;
    serialNumber?: string;
}

export class CxTreeNode {
    id?: number;
    key?: string;
    data?: { [index: string]: string };
    children?: CxTreeNode[];
}

export class CxValidator<T> {
}

export class VariableDto extends BaseDto {
    name?: string;
    defaultValue?: string;
}

export class VariableTypeDto extends BaseDto {
    name?: string;
    sortOrder?: number;
}

export class VariableValueDto extends BaseDto {
    value?: string;
    variable?: VariableDto;
    variableType?: VariableTypeDto;
    variableValueRels?: IdNameDto[];
}

export class RunCodeRequest {
    templateName?: string;
    templateId?: number;
    locationDeviceId?: number;
}

export class WorkerHeartbeatRequest {
    jobId?: number;
    output?: string;
    jobOutput?: CxJobOutput;
}

export class WorkerRequest {
    job?: JobDto;
}

export class WorkerResultRequest {
    output?: CxJobOutput;
    jobId?: number;
}

export class CxJobArgument {
    fileName?: string;
    version?: string;
    script?: string;
    meta?: any;
}

export class CxJobOutput {
    result?: CxJobResult;
    output?: string;
    error?: string;
    log?: string;
}

export class JobDto extends BaseDto {
    name?: string;
    type?: CxJobType;
    output?: CxJobOutput;
    status?: CxJobStatus;
    argument?: CxJobArgument;
    schedule?: IdNameDto;
    codeTemplate?: IdNameDto;
    locationDevice?: IdNameDto;
    workerLog?: string;
    workerRequestTime?: Date;
    workerHeartbeatTime?: Date;
    workerRequestCount?: number;
}

export class CxScheduleResult {
    error?: CxError;
    locationDeviceId?: number;
    locationDeviceName?: string;
    result?: CxProcessResult;
}

export class ScheduleDto extends BaseDto {
    name?: string;
    description?: string;
    jobType?: CxJobType;
    pattern?: CxSchedulePattern;
    state?: CxSchedulerState;
    scheduleLog?: string;
    activated?: boolean;
    interval?: boolean;
    patternTime?: Date;
    patternOffsets?: string;
    executionCount?: number;
    nextExecutionTime?: Date;
    lastExecutionTime?: Date;
    maxJobFailureCount?: number;
    firstInterval?: number;
    secondInterval?: number;
    thirdInterval?: number;
    codeTemplate?: CodeTemplateDto;
    locationDevices?: LocationDeviceDto[];
}

export class ScheduleJobDto {
    locationDeviceId?: number;
    locationDeviceName?: string;
    locationId?: number;
    locationName?: string;
    deviceRoleId?: number;
    deviceRoleName?: string;
    jobId?: number;
    jobStatus?: string;
    jobResult?: string;
}

export class ScheduleStatusDto {
    jobs?: ScheduleJobDto[];
    job?: JobDto;
    activated?: boolean;
    scheduleLog?: string;
    nextExecutionTime?: Date;
    lastExecutionTime?: Date;
    state?: CxSchedulerState;
}

export class CatalystCenterSite {
    id?: string;
    name?: string;
    parentId?: string;
    instanceTenantId?: string;
    siteHierarchy?: string;
    siteNameHierarchy?: string;
    additionalInfo?: any;
}

export class LocationDevice extends BaseEntity {
    name?: string;
    osName?: string;
    managementIp?: string;
    ztpContactTime?: Date;
    meta?: any;
    state?: CxDeviceState;
    location?: Location;
    deviceTemplate?: DeviceTemplate;
    deviceRole?: DeviceRole;
    loginProfile?: LoginProfile;
}

export class DeviceFamilyGroup extends BaseEntity {
    name?: string;
    deviceFamilies?: DeviceFamily[];
}

export class DeviceRole extends BaseEntity {
    name?: string;
    description?: string;
}

export class DeviceModel extends BaseEntity {
    name?: string;
    catalystCenterActivated?: boolean;
    deviceFamily?: DeviceFamily;
}

export class DeviceFamily extends BaseEntity {
    name?: string;
    manufacturer?: Manufacturer;
}

export class DeviceTemplate extends BaseEntity {
    name?: string;
    description?: string;
    os?: OS;
    isStack?: boolean;
    stackOrder?: CxStackMember[];
    deviceTemplateRoles?: DeviceTemplateRole[];
    deviceTemplateModels?: DeviceTemplateModel[];
}

export class Location extends BaseEntity {
    name?: string;
    city?: string;
    state?: string;
    street?: string;
    continent?: string;
    postCode?: string;
    streetNumber?: string;
    country?: string;
    catalystCenterActivated?: boolean;
    catalystCenterPath?: string;
    catalystCenter?: ApiParam;
    locationTypes?: LocationType[];
    locationCategory?: LocationCategory;
}

export class LocationType extends BaseEntity {
    name?: string;
    description?: string;
    locationCategories?: LocationCategory[];
    vlans?: Vlan[];
}

export class LocationCategory extends BaseEntity {
    name?: string;
    description?: string;
    locationTypes?: LocationType[];
    locationCategoryRoles?: LocationCategoryRole;
}

export class DeviceTemplateDtoFlat {
    id?: number;
    name?: string;
    isStack?: boolean;
    os?: IdNameDto;
    deviceRoles?: IdNameDto[];
    deviceTemplateModels?: DeviceTemplateModelDtoFlat[];
}

export class OsImage {
    mainFile?: boolean;
    patchFile?: boolean;
    easyDeploy?: boolean;
    path?: string;
    filename?: string;
    checksum?: string;
}

export class DeviceTemplateModelDtoFlat {
    id?: number;
    deviceModel?: IdNameDto;
    stackOrder?: number;
    stackPriority?: number;
}

export class Vlan extends BaseEntity {
    name?: string;
    vlanId?: number;
    hsrpGroup?: string;
    configurationText?: string;
    meta?: any;
    virtualNetwork?: VirtualNetwork;
}

export class LoginProfile extends BaseEntity {
    name?: string;
    loginType?: CxLoginType;
    username?: string;
    password?: string;
    default?: boolean;
}

export class Manufacturer extends BaseEntity {
    name?: string;
}

export class OS extends BaseEntity {
    name?: string;
    version?: string;
    filename?: string;
    checksum?: string;
    path?: string;
    osImages?: OsImage[];
}

export class DeviceTemplateRole extends BaseEntity {
    deviceTemplate?: DeviceTemplate;
    deviceRole?: DeviceRole;
}

export class DeviceTemplateModel extends BaseEntity {
    stackOrder?: number;
    stackPriority?: number;
    deviceModel?: DeviceModel;
    deviceTemplate?: DeviceTemplate;
}

export class ApiParam extends BaseEntity {
    url?: string;
    type?: CxApiType;
    username?: string;
    password?: string;
    name?: string;
}

export class LocationCategoryRole extends BaseEntity {
    minCount?: number;
    maxCount?: number;
    deviceRole?: DeviceRole;
    locationCategory?: LocationCategory;
}

export class VirtualNetwork extends BaseEntity {
    asn?: string;
    name?: string;
    vpnNumber?: number;
    description?: string;
    meta?: any;
}

export enum CxPermission {
    NONE = "NONE",
    ROLE = "ROLE",
    DEVICE_MODEL = "DEVICE_MODEL",
    MANUFACTURER = "MANUFACTURER",
    CONTACT = "CONTACT",
    CONTACT_TYPE = "CONTACT_TYPE",
    WORKER_TEMPLATE = "WORKER_TEMPLATE",
    VARIABLE = "VARIABLE",
    VARIABLE_ORDER = "VARIABLE_ORDER",
    VARIABLE_VALUE = "VARIABLE_VALUE",
    OS = "OS",
    OS_IMAGE = "OS_IMAGE",
    PORT_USE = "PORT_USE",
    PORT_TYPE = "PORT_TYPE",
    LOCATION = "LOCATION",
    LOCATION_JOB = "LOCATION_JOB",
    LOCATION_DEVICE = "LOCATION_DEVICE",
    LOCATION_DEVICE_ACCESS = "LOCATION_DEVICE_ACCESS",
    LOCATION_DEVICE_CONFIG = "LOCATION_DEVICE_CONFIG",
    LOCATION_TYPE = "LOCATION_TYPE",
    LOCATION_CATEGORY = "LOCATION_CATEGORY",
    LOCATION_CATEGORY_ROLE = "LOCATION_CATEGORY_ROLE",
    LOCATION_CATEGORY_MAPPING = "LOCATION_CATEGORY_MAPPING",
    SCHEDULER_JOB = "SCHEDULER_JOB",
    DEVICE_TEMPLATE = "DEVICE_TEMPLATE",
    DEVICE_TEMPLATE_PORT = "DEVICE_TEMPLATE_PORT",
    DEVICE_ROLE = "DEVICE_ROLE",
    DEVICE_ROLE_PORT_USE = "DEVICE_ROLE_PORT_USE",
    DEVICE_FAMILY = "DEVICE_FAMILY",
    VIRTUAL_NETWORK = "VIRTUAL_NETWORK",
    IP_POOL = "IP_POOL",
    IP_POOL_SUBNET = "IP_POOL_SUBNET",
    CONFIGURATION = "CONFIGURATION",
    LOGIN_PROFILE = "LOGIN_PROFILE",
    CODE_TEMPLATE = "CODE_TEMPLATE",
    SCHEDULE_OUTPUT = "SCHEDULE_OUTPUT",
    LOGICAL_INTERFACE = "LOGICAL_INTERFACE",
    LOCATION_DEVICE_IP = "LOCATION_DEVICE_IP",
    APP_CONTEXT = "APP_CONTEXT",
    JOB = "JOB",
    API_PARAM = "API_PARAM",
    COMPLIANCE = "COMPLIANCE",
    RULE = "RULE",
    COMPARISON = "COMPARISON",
}

export enum CxPermissionLevel {
    NONE = "NONE",
    READ = "READ",
    WRITE = "WRITE",
    APPROVAL = "APPROVAL",
}

export enum CxApprovalState {
    NONE = "NONE",
    PENDING = "PENDING",
    REVIEW = "REVIEW",
    REJECTED = "REJECTED",
    HISTORY = "HISTORY",
    APPROVED = "APPROVED",
}

export enum ConfigurationType {
    GLOBAL = "GLOBAL",
    FUNCTIONAL = "FUNCTIONAL",
    DEVICE = "DEVICE",
    SPECIAL = "SPECIAL",
}

export enum CxCodeTemplateType {
    PYTHON = "PYTHON",
}

export enum CxConfigSource {
    GENERATED = "GENERATED",
    RUNNING_CONFIG = "RUNNING_CONFIG",
}

export enum CxConfigStatus {
    NONE = "NONE",
    ERROR = "ERROR",
    WARN = "WARN",
    SUCCESS = "SUCCESS",
    OUTDATED = "OUTDATED",
}

export enum CxLoginType {
    SSH = "SSH",
    SSH_CERT = "SSH_CERT",
    HTTP = "HTTP",
    HTTPS = "HTTPS",
}

export enum CxApiType {
    ACI = "ACI",
    CATALYST_CENTER = "CATALYST_CENTER",
    MERAKI = "MERAKI",
    ISE = "ISE",
    STEALTHWATCH = "STEALTHWATCH",
    THOUSANDEYES = "THOUSANDEYES",
    DNAC="DNAC",
    EOX="EOX",
    BUG_API="BUG_API",
}

export enum CxDeviceState {
    DAY0_UNCLAIMED = "DAY0_UNCLAIMED",
    DAY0_PROCESSING = "DAY0_PROCESSING",
    DAY0_PROVISIONED = "DAY0_PROVISIONED",
    DAY0_ERROR = "DAY0_ERROR",
    DAYN_IDLE = "DAYN_IDLE",
    DAYN_PROCESSING = "DAYN_PROCESSING",
    DAYN_PROVISIONED = "DAYN_PROVISIONED",
    DAYN_ERROR = "DAYN_ERROR",
}

export enum CxEntityType {
    GLOBAL = "GLOBAL",
    DEVICE_ROLE = "DEVICE_ROLE",
    DEVICE_MODEL = "DEVICE_MODEL",
    DEVICE_FAMILY = "DEVICE_FAMILY",
    DEVICE_TEMPLATE = "DEVICE_TEMPLATE",
    MANUFACTURER = "MANUFACTURER",
    LOCATION = "LOCATION",
    LOCATION_TYPE = "LOCATION_TYPE",
    LOCATION_DEVICE = "LOCATION_DEVICE",
    LOCATION_CATEGORY = "LOCATION_CATEGORY",
    LOGIN_PROFILE = "LOGIN_PROFILE",
    CONFIGURATION = "CONFIGURATION",
    CODE_TEMPLATE = "CODE_TEMPLATE",
    SCHEDULE = "SCHEDULE",
    JOB = "JOB",
    API_PARAM = "API_PARAM",
    COMPLIANCE = "COMPLIANCE",
    RULE = "RULE",
    DEVICE_FAMILY_GROUP = "DEVICE_FAMILY_GROUP",
    COMPARISON_CONFIGURATION = "COMPARISON_CONFIGURATION",
}

export enum CxExportType {
    CSV = "CSV",
    XLSX = "XLSX",
    JSON = "JSON",
}

export enum CxIPPoolType {
    PREALLOCATED = "PREALLOCATED",
    VLSM = "VLSM",
}

export enum CxMappingType {
    TEXT = "TEXT",
    DATE = "DATE",
    NAMES_FROM_ARRAY = "NAMES_FROM_ARRAY",
}

export enum CxNetworkProtocol {
    IPV4 = "IPV4",
    IPV6 = "IPV6",
}

export enum CxNetworkType {
    ACCESS = "ACCESS",
    LOOPBACK = "LOOPBACK",
    DEVICE = "DEVICE",
    TRANSFER = "TRANSFER",
}

export enum Language {
    DE = "DE",
    EN = "EN",
    FR = "FR",
}

export enum CxJobResult {
    NONE = "NONE",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
}

export enum CxJobStatus {
    CREATED = "CREATED",
    PENDING = "PENDING",
    PROCESSING = "PROCESSING",
    CANCELLED = "CANCELLED",
    DONE = "DONE",
}

export enum CxJobType {
    ROLLOUT = "ROLLOUT",
    ONESHOT = "ONESHOT",
    COMPLIANCE = "COMPLIANCE",
    PUSH = "PUSH",
    CUSTOM = "CUSTOM",
}

export enum CxSchedulePattern {
    ONESHOT = "ONESHOT",
    MINUTELY = "MINUTELY",
    HOURLY = "HOURLY",
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
    YEARLY = "YEARLY",
}

export enum CxSchedulerState {
    INACTIVE = "INACTIVE",
    RUNNING = "RUNNING",
}
