
import {
  CxDataType,
  CxTableColumn,
  CxTableConfig,
} from "@/types/cx-table-config";
import { CxExportType } from "@/types/dto";
import { defineComponent } from "vue";
import CxDeleteButton from "@/components/CxDeleteButton.vue";

export default defineComponent({
  components: {CxDeleteButton},
  props: {},
  data() {
    return {
      deviceModels: []
    };
  },
  computed: {
    tableConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn(
          "deviceFamily",
          this.$t("deviceFamily", "lb"),
          CxDataType.GetName
        ),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
    importTableConfig(): CxTableConfig {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("deviceFamily", "", CxDataType.Custom),
      ])
    },
  },
  methods: {
    load(filters: any) {
      this.$store
        .dispatch("deviceModel/getWithFilter", filters)
        .then((deviceModels) => {
          this.deviceModels = deviceModels;
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onExport() {
      this.$store.dispatch("deviceModel/export", CxExportType.CSV);
    },
    onDelete(data: any) {
      this.$cx.confirmDeleteDetail(
        data.name,
        () => {
          this.$store
            .dispatch("deviceModel/delete", data.id)
            .then(() => {
              this.$cx.notifyDeleted(this.$t("deviceModel", "lb"));
              (<any>this.$refs)['tbl'].reload()
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
        })
    },
    getAllBugs() {
      this.$cx.confirm(this.$t("ciscoBug", "confirmAllBugsTitle"), this.$t("ciscoBug", "confirmAllBugsMessage"),() => {
        console.log("getAllBugs")
        this.$store.dispatch("bugApi/saveBugsForAllDevices")
        .then(() => {
          this.$cx.notifyGeneric(this.$t("ciscoBug", "savedBugsForAllTitle"),this.$t("ciscoBug", "savedBugsForAllMessage"));
          console.log("gotAllBugs")
        })
      })
    },
    getBugsByModel(data: any) {
      this.$cx.confirm(this.$t("ciscoBug", "confirmBugsForOneTitle")+data.name+"?", this.$t("ciscoBug", "confirmBugsForOneMessage"),() => {
        console.log("getBugsByModel - " + data.name)
        this.$store.dispatch("bugApi/saveBugsForDeviceModel", data.id)
        .then(() => {
          this.$cx.notifyGeneric(this.$t("ciscoBug", "savedBugsForOneTitle"),this.$t("ciscoBug", "savedBugsForOneMessage")+data.name);
          console.log("gotBugsByModel - " + data.name)
        })
      })
    },
    onClearFilters() {
      (<any>this.$refs)['tbl'].clearFilters()
    }
  },
});
