
import {
  ConfigurationDto,
  CxConfigResult,
  LocationDeviceDto,
  SerialNumberDto,
  CiscoPnpRequestDto,
  LocationDeviceIpDto,
  CxEntityType,
  CxApprovalState,
  CxConfigSource, EoxRecordDto,
} from "@/types/dto";
import { defineComponent } from "vue";
import { CxFormApi } from "@/types/cx-form-api";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Listbox from "primevue/listbox";
import Textarea from "primevue/textarea";
import { Diff } from "vue-diff";
import {
  CxTableConfig,
  CxTableColumn,
  CxDataType,
} from "@/types/cx-table-config";
import { ApprovalState } from "@/types/approval-state";

export default defineComponent({
  props: {
    id: String,
  },
  data() {
    return {
      configOutput: {} as any,
      isConfigResultDialogVisible: false,
      configResult: "" as any,
      configResultHeader: "" as any,
      entity: {} as LocationDeviceDto,
      initialEntity: {} as LocationDeviceDto,
      deviceApi: {} as CxFormApi,
      pnpRequestData: [] as CiscoPnpRequestDto[],
      configurations: [] as ConfigurationDto[],

      serialNumbers: [] as SerialNumberDto,
      locationDeviceIps: [] as any,
      serialNumbersOrdered: [] as any,
      changesToApprove: {} as any,
      approve: CxEntityType.LOCATION_DEVICE,
      approvalState: new ApprovalState(CxEntityType.LOCATION_DEVICE),
      eoxRecords: [] as EoxRecordDto[],

      tabIndex: 0
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  computed: {
    isEditing() {
      return this.id != null;
    },
    serialNumberTableConfig() {
      let tc = new CxTableConfig([
        //new CxTableColumn("id", "id"),
        new CxTableColumn("sortOrder", "", CxDataType.Custom),
        new CxTableColumn("stackOrder", this.$t("deviceTemplateModel", "stackOrder")),
        new CxTableColumn("serialNumber", this.$t("serialNumber", 'lb')),
        new CxTableColumn("deviceModel", this.$t("deviceModel", 'lb'), CxDataType.Custom),
        new CxTableColumn("stackPriority", this.$t("deviceTemplateModel", "stackPriority"))
      ]);
      return tc;
    },
    pnpRequestTableConfig() {
      return new CxTableConfig([
        new CxTableColumn("serialNumber", this.$t("serialNumber", 'lb')),
        new CxTableColumn("correlator", this.$c("correlator")),
        new CxTableColumn("fileName", this.$c("fileName")),
        new CxTableColumn("successful", this.$c("successful"), CxDataType.Checkbox),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
    locationDeviceIpTableConfig() {
      return new CxTableConfig([
        new CxTableColumn("interface", this.$c("port")),
        new CxTableColumn("ip", this.$c("ip")),
        new CxTableColumn("subnet", this.$c("subnet")),
        new CxTableColumn("description", this.$c("description"))
      ])
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.deviceApi.notifyUnsavedChanges(next);
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.deviceApi.load(this.id);
        this.loadPnpRequests();
        this.loadSerialNumbers();
        this.loadZtpLogs();
        this.loadConfigs();
        this.loadEoxRecords();
        this.approvalState.setStore("locationDevice", this.id!)
        this.approvalState.fetchApprovalData()
      }
    },
    loadConfigs() {
      (this.$refs as any)["diff"].onDeviceChanged(null)
    },
    loadEoxRecords() {
      this.$store
      .dispatch("eox/getEoxForDevice", this.id)
      .then((eoxRecordList) => {
        this.eoxRecords = eoxRecordList;
      });
    },
    async save(goBack: Boolean) {
      this.deviceApi.onSave((newEntity: LocationDeviceDto) => {
        if (this.isEditing) {
          if (goBack)
            this.$cx.goToById("locationEditor", this.entity!.location!.id!);
        } else {
          if (goBack)
            this.$cx.goToById("locationEditor", this.entity!.location!.id!);
          else this.$cx.goToById("locationDeviceEditor", newEntity.id!);
        }
      });
      if (this.tabIndex == 1) this.onSaveSerialNumbers();
    },
    onTabChanged(ev: any) {
      this.tabIndex = ev.index
    },

    // deprecated
    onWorkerRun(template: string, title: string) {
      this.$store
        .dispatch("codeTemplate/run", {
          locationDeviceId: this.id,
          templateName: template,
        })
        .then((result) => {

          try {
            this.configOutput = JSON.parse(result.output.replaceAll("'", '"'))
          } catch (err) {
            console.error(err)
          }

          this.configResult = result;
          this.isConfigResultDialogVisible = true;
          this.configResultHeader = title;
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("workerRun")));
    },
    onGenerateConfig() {
      localStorage.setItem(
        "cx.lastCompiledLocationDeviceName",
        this.entity.name!
      );
      localStorage.setItem("cx.lastCompiledLocationDeviceId", this.id!);
      this.$store
        .dispatch("locationDevice/generateAndSave", this.id!)
        .then((config: CxConfigResult) => {
          console.log(this.entity.name)
          this.$cx.success(
            this.$t("locationDevice", "generatedSuccess"),
            this.entity.name!,
          );
          this.loadConfigs();
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("generating")));
    },

    // Serial Numbers
    loadSerialNumbers() {
      this.$store
        .dispatch("locationDevice/getSerials", this.id)
        .then((serialNumbers) => {
          this.serialNumbers = serialNumbers;
          this.serialNumbersOrdered = serialNumbers;
        });
    },

    // PnpRequests
    loadPnpRequests() {
      this.$store
        .dispatch("pnpRequest/getPnpRequestsByLocationDeviceId", this.id)
        .then((pnpRequests) => {
          this.pnpRequestData = pnpRequests;
        })
        .catch((error) => console.error(error));
    },

    // Ips
    loadLocationDeviceIps(filters: any) {
      filters.id = this.id
      this.$store
        .dispatch("locationDeviceIp/getWithFilter", filters)
        .then((locationDeviceIps) => {
          this.locationDeviceIps = locationDeviceIps;
          this.locationDeviceIps.content = this.locationDeviceIps.content.map((i: LocationDeviceIpDto) => {
            let intf = ""
            if (i.devicePort != null)
              intf = i.devicePort!.devicePortType!.name! + i.devicePort!.portInterface!
            else if (i.logicalInterface != null)
              intf = i.logicalInterface.name!
            else if (i.vlan != null)
              intf = 'VLAN ' + i.vlan!.name!
            else if (i.virtualNetwork != null)
              intf = 'VRF ' + i.virtualNetwork!.name!
            return ({ ...i, ...{ interface: intf } });
          })
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onReorder(ev: any) {
      this.serialNumbersOrdered = ev.value
      for (let i = 0; i < this.serialNumbersOrdered.length; i++)
        this.serialNumbersOrdered[i].stackOrder = i + 1
    },
    onSaveSerialNumbers() {
      this.$store
        .dispatch("serialNumber/saveAll", this.serialNumbersOrdered.filter((s: SerialNumberDto) => s.id! > 0 && !this.$cx.isNullOrEmpty(s.serialNumber)))
        .then(() => {
          this.$cx.notifySaved(this.$t("serialNumber", "lb"));
          this.loadSerialNumbers();
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    loadZtpLogs() {
      (this.$refs as any)['ztp-viewer'].getLogsByLocationDeviceId(this.id);
    },

    // Config-Tab, Filters
    onRemoveFilter(arr: any[], value: any) {
      this.$cx.removeFrom(arr as any[], value, "value");
    },
    isRunningConfig(config: ConfigurationDto) {
      return config.source == CxConfigSource.RUNNING_CONFIG
    },
    onOpenConfigWizard(config: ConfigurationDto) {
      this.$cx.goToNewTabWithArgs('configurationWizard', {
        locationDeviceId: this.id,
        configId: config.id
      })
    },

    // Approvals

    onApprovalViewChanged(data: any, isViewingChanges: boolean) {
      if (isViewingChanges) {
        (this.$refs as any)['approval'].apply(this.entity, data)
      } else {
        (this.$refs as any)['approval'].apply(this.entity, (this.$refs as any)['locDevForm'].initialEntity)
      }
    },

    onApprovalChanged(state: CxApprovalState) {
      (this.$refs as any)['approval'].reset()
      this.load()
    },

    onEntityChanged(entity: any, initialEntity: any) {
      this.entity = entity
      this.initialEntity = initialEntity
    }
  },
});
