import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3092794f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "record-container"
}
const _hoisted_2 = { class: "shortened-description" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_ctx.bugs.length>0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_DataTable, {
            value: _ctx.bugs,
            paginator: false,
            rows: _ctx.filters.size,
            totalRecords: _ctx.totalRecords,
            lazy: true,
            loading: _ctx.loading,
            first: _ctx.filters.page * _ctx.filters.size,
            onPage: _ctx.onPageChange,
            paginatorTemplate: _ctx.customPaginatorTemplate,
            currentPageReportTemplate: _ctx.currentPageReportTemplate
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "basePid",
                header: "Base PID"
              }),
              _createVNode(_component_Column, {
                field: "bugId",
                header: "Bug ID"
              }),
              _createVNode(_component_Column, {
                field: "headline",
                header: _ctx.$t('ciscoBug', 'headline')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('ciscoBug', 'description')
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("span", _hoisted_2, [
                    _createTextVNode(_toDisplayString(_ctx.shortenDescription(slotProps.data.description)) + " ", 1),
                    _createVNode(_component_Button, {
                      icon: "pi pi-window-maximize",
                      class: "p-button-text p-button-sm",
                      onClick: ($event: any) => (_ctx.showFullDescription(slotProps.data.description))
                    }, null, 8, ["onClick"])
                  ])
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "severity",
                header: _ctx.$t('ciscoBug', 'severity')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "status",
                header: _ctx.$t('ciscoBug', 'status')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "lastModifiedDate",
                header: _ctx.$t('ciscoBug', 'lastModifiedDate')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "products",
                header: _ctx.$t('ciscoBug', 'products')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "knownAffectedReleases",
                header: _ctx.$t('ciscoBug', 'knownAffectedReleases')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "knownFixedReleases",
                header: _ctx.$t('ciscoBug', 'knownFixedReleases')
              }, null, 8, ["header"])
            ]),
            _: 1
          }, 8, ["value", "rows", "totalRecords", "loading", "first", "onPage", "paginatorTemplate", "currentPageReportTemplate"]),
          _createVNode(_component_Dialog, {
            header: _ctx.$t('ciscoBug', 'fullDescriptionHeader'),
            visible: _ctx.descriptionDialogVisible,
            "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.descriptionDialogVisible) = $event)),
            modal: "",
            style: { width: '60%' },
            dismissableMask: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.fullDescription), 1)
            ]),
            _: 1
          }, 8, ["header", "visible"])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("ciscoBug", "noBugsFound")), 1)
      ]))
}